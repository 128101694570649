import React from "react";

const GridComponent = () => {
  return (
    <>
      <div className="flex justify-center text-black margin-auto mt-10 text-3xl font-bold">
        About Us
      </div>

      <div className="grid md:grid-cols-1 lg:grid-cols-3 gap-6 p-4 md:p-8 py-10">
        <div className="bg-gray-100 p-4">
          <img
            src="https://img1.wsimg.com/isteam/ip/3ff682ac-d5c4-45c7-a051-4d7efd54c186/Mission.jpg/:/cr=t:0%25,l:15.98%25,w:68.05%25,h:100%25/rs=w:365,h:365,cg:true"
            alt="Mission"
            className="mx-auto mb-4"
          />
          <h2 className="text-lg font-semibold mb-2">Our Mission</h2>
          <p className="text-gray-600">
          At SPROUTIVE, our mission is to create a world where healthy eating is the norm, not the exception. We believe that everyone deserves access to fresh, wholesome food that is free from harmful chemicals and additives. That's why we are committed to using only the best ingredients in our meals, and why we work hard to make healthy eating accessible and affordablefor everyone.          </p>
        </div>

        <div className="bg-gray-100 p-4">
          <img
            src="https://img1.wsimg.com/isteam/ip/3ff682ac-d5c4-45c7-a051-4d7efd54c186/WhatsApp%20Image%202023-03-05%20at%202.32.55%20PM.jpeg/:/cr=t:12.5%25,l:0%25,w:100%25,h:75%25/rs=w:365,h:365,cg:true"
            alt="Values"
            className="mx-auto mb-4"
          />
          <h2 className="text-lg font-semibold mb-2">Our Values</h2>
          <p className="text-gray-600">
          At SPROUTIVE, we believe in:

Quality: We use only the best ingredients and hygiene in our meals, and we are committed to delivering the highest quality products and services to our customers.

Accessibility: We believe that everyone deserves access to healthy food, which is why we strive to make our products and services accessible and affordable for all.

Innovation: We are constantly striving to improve and innovate, both in terms of the food we create and the way we do business.             </p>
        </div>

        <div className="bg-gray-100 p-4">
          <img
            src="https://img1.wsimg.com/isteam/ip/3ff682ac-d5c4-45c7-a051-4d7efd54c186/WhatsApp%20Image%202023-03-04%20at%207.59.10%20PM.jpeg/:/cr=t:0%25,l:23.16%25,w:53.67%25,h:100%25/rs=w:365,h:365,cg:true"
            alt="Products"
            className="mx-auto mb-4"
          />
          <h2 className="text-lg font-semibold mb-2">Our Products</h2>
          <p className="text-gray-600">
          At SPROUTIVE, we offer a range of healthy meals that are perfect for busy people on-the-go. From smoothie bowls and cereals to oats meals and sauteed vegetables, our meals are packed with nutrients and flavour, and are designed to help you feel your best.

Our products are packed post receiving your order, its truly FRESH!
          </p>
        </div>
      </div>
    </>
  );
};

export default GridComponent;
