import { query } from "firebase/database";
import {
  collection,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useCart } from "../../cookies/CartProvider";

const ProductListing = ({ category }) => {
  const [products, setProducts] = useState(null);
  const db = getFirestore();
  const { cartItems, addToCart, removeFromCart, clearCart, onIncrement, onDecrement } = useCart();

  useEffect(() => {
    // Function to fetch data from Firestore
    const fetchProducts = async () => {
      try {
        const productsCollection = collection(db, "products");
        const categoryQuery = query(
          productsCollection,
          where("category", "==", category),
          limit(10)
        );
        const unsubscribe = onSnapshot(categoryQuery, (snapshot) => {
          var formattedProducts = snapshot.docs.map((doc) => {
            const productData = doc.data();
            return {
              image: productData.downloadUrls[0], // Use the first URL from downloadUrls as imageUrl
              name: productData.name,
              price: productData.price,
              id: doc.id, // Use the document ID as the key for each product
              originalPrice: productData.originalPrice,
              cart: 0
            };
          });

          cartItems.forEach((elem) => {
            formattedProducts = formattedProducts.map((ele) => {
              if (ele.id === elem.id) {
                return {
                  ...ele,
                  cart: elem.quantity,
                };
              } else {
                return ele; // Return unchanged element when the condition doesn't match
              }
            });
          });



          setProducts(formattedProducts);
        });

        return () => {
          unsubscribe();
        };
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, [db]);


  const handleAddToCart = (productId) => {
    // Create a copy of the products array to avoid mutating state directly
    const updatedProducts = [...products];
    // Find the product in the array by ID
    const productIndex = updatedProducts.findIndex((product) => product.id === productId);
    // Increment the cart value for the specific product
    updatedProducts[productIndex].cart += 1;
    // Update the state with the new array
    console.log(updatedProducts)

    setProducts(updatedProducts);
    addToCart(productId);
    vibrateDevice();
  };

  const handleRemoveFromCart = (productId) => {
    const updatedProducts = [...products];
    // Find the product in the array by ID
    const productIndex = updatedProducts.findIndex((product) => product.id === productId.id);
    // Increment the cart value for the specific product
    updatedProducts[productIndex].cart -= 1;
    // Update the state with the new array
    console.log(updatedProducts)

    setProducts(updatedProducts);
    if(productId.cart == 0) {
      removeFromCart(productId)
    }else{
      onDecrement(productId);


    }

    console.log(cartItems)
    vibrateDevice();
  }


  const vibrateDevice = () => {
    if ("vibrate" in navigator) {
      navigator.vibrate(100);
    }
  };

  return (
    <div className="bg-gray-100 p-8 ">
      <h2 className="text-2xl font-semibold text-center mb-10">{category}</h2>
      {products == null ? (
        <>Loading...</>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6 ">
          {products.map((product) => (
            <div
              key={product.id}
              className="bg-white p-4 py-10 rounded-lg text-center shadow-md flex flex-col justify-between"
              style={{ height: "100%", minHeight: "260px" }} // Set min height for responsive square shape
            >
              <Link className="relative block" to={"product/" + product.id}>
                <div className="absolute top-1 right-1 font-bold rounded p-2 bg-green-400 percentage-off">
                  {(
                    ((product.originalPrice - product.price) /
                      product.originalPrice) *
                    100
                  ).toFixed(0)}
                  % Off
                </div>
                <img
                  src={product.image}
                  alt={product.name}
                  className="w-full h-80 object-cover rounded-lg mb-2"
                />
                <h3 className="text-lg font-semibold mb-2 line-clamp-1	">{product.name}</h3>
                <div className="flex flex-column justify-center gap-2">
                  <p className="font-bold mb-2 text-red-500 line-through">
                    ₹{product.originalPrice}
                  </p>
                  <p className="text-gray-600 font-bold mb-2">
                    ₹{product.price}
                  </p>
                </div>
              </Link>
              {product.cart == 0 ?
                <button
                  onClick={() => handleAddToCart(product.id)} // Call the function to handle adding to cart
                  className="flex-1 bg-green-400 text-black font-bold py-2 px-4 rounded-lg border border-black"
                  style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}
                >
                  Add 
                </button>
                :
                <div className="flex bg-white border rounded-lg  border-black  flex-row justify-center gap-10 items-center">
                  <button
                    onClick={() => handleRemoveFromCart(product)}
                    className="text-black w-full text-2xl bg-green-400 p-1 rounded-lg  left-0 font-bold   border-none outline-none cursor-pointer"
                    style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}

                  >
                    -
                  </button>
                  <div
                    className="text-black font-bold p-2 px-4 "
                  >
                    {product.cart}
                  </div>
                  <button
                    onClick={() => handleAddToCart(product.id)}
                    className="text-black w-full text-2xl bg-green-400 p-1 right-0 font-bold rounded-lg  border-none outline-none cursor-pointer"
                    style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}
                  >
                    +
                  </button>
                  </div>
              }
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProductListing;
