import GridComponent from "../components/About/AboutUsContent";
import Slider from "../components/App/Slider";
import Footer from "../components/common/Footer";
import Header from "../components/common/Header";

function About() {
  return (
    <div className="App">
        <Header></Header>
        <Slider></Slider>
        <GridComponent></GridComponent>
        <Footer></Footer>
    </div>
  );
}

export default About;
