import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Header from '../components/common/Header'
import Slider from '../components/App/Slider'
import Footer from '../components/common/Footer'
import ProductListing from '../components/App/SectionProducts'
import { collection, getDocs, getFirestore } from 'firebase/firestore'
import { storeScroll } from '../redux/couter'


 function App() {
  // const scrollx = useSelector((state) => state.counter.value)
  // const dispatch = useDispatch()
  const [categories, setCategories] = useState(null);




 

  const db = getFirestore(); // Get the Firestore instance

  // Fetch categories from Firestore on component mount
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesCollectionRef = collection(db, 'categories');
        const categoriesSnapshot = await getDocs(categoriesCollectionRef);
        const categoriesData = categoriesSnapshot.docs.map((doc) => doc.data());
        setCategories(categoriesData);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  if(categories==null){
    return<>Loading...</>
  }

  return (
    <div>
      <Header></Header>
      <Slider></Slider>
      {categories == null ? 
      <> Loading...</>:
      categories.map((category, index) => (
        <ProductListing category={category.name}></ProductListing>
      ))
      }
     
      

      <Footer></Footer>
    </div>
  )
}


export default App