import { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  doc,
  addDoc,
  updateDoc,
  deleteDoc,
  onSnapshot,
} from "firebase/firestore";
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL, uploadBytesResumable } from "firebase/storage";


const EditProductModal = ({ showModal, setShowModal, product, productId }) => {
  const [name, setName] = useState(product.name);
  const [price, setPrice] = useState(product.price);
  const [originalPrice, setOriginalPrice] = useState(product.originalPrice);
  const [category, setCategory] = useState(product.category);
  const [description, setDescription] = useState(product.description);
  const [images, setImages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [uploadProgress, setUploadProgress] = useState("Upload Images");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successAdd, setSuccessAdd] = useState(true);
  const [msg, setMsg] = useState("");
  const [editedDescription, setEditedDescription] = useState([...product.description]);
  const [editedDetails, setEditedDetails] = useState([]);
  const [newDescription, setNewDescription] = useState("");
  const [newDetailName, setNewDetailName] = useState("");
  const [newDetailItems, setNewDetailItems] = useState("");
  const [rating, setRating] = useState(product.rating);
  const [stock,setStock] = useState();
  const [buyInfo,setBuyInfo] =useState();
  const [currency,setCurrency] = useState();

  console.log(product.downloadUrls);
  const [downloadUrls, setdownloadUrls] = useState(product.downloadUrls);

  const storage = getStorage();
  const firestore = getFirestore();

  useEffect(() => {
    const categoriesCollection = collection(firestore, "categories");
    const unsubscribe = onSnapshot(categoriesCollection, (snapshot) => {
      const categoriesData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCategories(categoriesData);
    });

    return () => unsubscribe();
  }, [firestore]);


  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handlePriceChange = (event) => {
    setPrice(Number(event.target.value));
  };

  const handleOriginalPriceChange = (event) => {
    setOriginalPrice(Number(event.target.value));
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };



  const handleImageRemove = (index) => {
    console.log(downloadUrls.length)
    if(downloadUrls.length!=1){
      const updatedUrls = [...downloadUrls];
    updatedUrls.splice(index, 1);
    setdownloadUrls(updatedUrls);
    }
    else{
      setMsg("You can't remove all images")

    }
  };

  const handleAddDescription = () => {
    if (newDescription.trim() !== "") {
      setEditedDescription((prev) => [...prev, newDescription.trim()]);
      setNewDescription("");
    }
  };

  const handleAddDetail = () => {
    if (newDetailName.trim() !== "" && newDetailItems.trim() !== "") {
      setEditedDetails((prev) => [
        ...prev,
        { name: newDetailName.trim(), items: newDetailItems.trim() },
      ]);
      setNewDetailName("");
      setNewDetailItems("");
    }
  };

  const handleDescriptionChange = (index, event) => {
    const newDescription = [...editedDescription];
    newDescription[index] = event.target.value;
    setEditedDescription(newDescription);
  };

  const handleDetailsChange = (index, field, event) => {
    const newDetails = [...editedDetails];
    newDetails[index][field] = event.target.value;
    setEditedDetails(newDetails);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(editedDescription);
    const product = {
      name,
      price,
      originalPrice,
      category,
      rating,
      description: editedDescription,
      downloadUrls,
    };
    if(name=="" || category=="" || price =="" ){
      setMsg("Please complete all fields")
    }else{
      handleEditProductdata(product);
      setName("");
      setPrice("");
      setOriginalPrice("");
      setCategory("");
      setdownloadUrls([]);
      setImages([]);

    }


    
    // setShowModal(false);
  };

  const handleEditProductdata = async (product) => {
    try {
      const productsCollection = collection(firestore, "products");
      const productDoc = doc(productsCollection, productId);
      await updateDoc(productDoc, { ...product });
      setSuccessAdd(true);
      setMsg("Product updated successfully");
    } catch (error) {
      console.log(error.message);
    }
  }

  // ...

  const handleRemoveDescription = (index) => {
    const newDescription = [...editedDescription];
    newDescription.splice(index, 1);
    setEditedDescription(newDescription);
  };

  const handleRemoveDetail = (index) => {
    const newDetails = [...editedDetails];
    newDetails.splice(index, 1);
    setEditedDetails(newDetails);
  };


  
  const handleRating = (event) => {
    const rat = Number(event.target.value)
    if(rat <= 5){
      setRating(rat);
    }
  };

  const handleStock = (event) => {
    setStock(event.target.value);
  };

  const handleBuyInfo = (event) => {
    setBuyInfo(event.target.value);
  };

  const handleCurrency = (event) => {
    setCurrency(event.target.value);
  };


  
  const handleImageChange = (event) => {
    const selectedImages = Array.from(event.target.files).slice(0, 4);
    setImages((prevImages) => [...prevImages, ...selectedImages]);
  };

  const handleImageCancel = (index) => {
    setImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.splice(index, 1);
      return updatedImages;
    });
  };

  const handleUpload = async (event) => {
    event.preventDefault();


    try {
      
      const storageRe = storageRef(storage, "images");
  
      const uploadTasks = images.map((image) => {
        const imageRef = storageRef(storageRe, image.name);
        const uploadTask = uploadBytesResumable(imageRef, image);
  
        uploadTask.on("state_changed", (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setUploadProgress("Uploading Please Wait");
        });
  
        return uploadTask;
      });
  
      await Promise.all(uploadTasks);

      const downloadUrls = await Promise.all(
        images.map((image) => {
          const imageRef = storageRef(storageRe, image.name);
          return getDownloadURL(imageRef);
        })
      );
      setdownloadUrls(downloadUrls);
      
      setUploadProgress("Upload Images");
      setImages([]);
      setSuccess(true);
      console.log("Download URLs:", downloadUrls);

    } catch (error) {
      setError(error.message);
    }

  }


  return (
    <div
  className={`fixed top-0 left-0 h-full w-full flex items-center justify-center  max-h-screen overflow-y-auto ${
    showModal ? "" : "hidden"
  }`}
>
  <div className="bg-gray-100 max-h-screen overflow-y-auto w-1/2 shadow-lg rounded-lg pt-8">
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-2">Edit Product</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="name" className="block text-gray-700 font-bold mb-2">
            Name
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={handleNameChange}
            className="border rounded-md px-4 py-2 w-full"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="price" className="block text-gray-700 font-bold mb-2">
            Price
          </label>
          <input
            type="number"
            id="price"
            value={price}
            onChange={handlePriceChange}
            className="border rounded-md px-4 py-2 w-full"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="originalPrice"
            className="block text-gray-700 font-bold mb-2"
          >
            Original Price
          </label>
          <input
            type="number"
            id="originalPrice"
            value={originalPrice}
            onChange={handleOriginalPriceChange}
            className="border rounded-md px-4 py-2 w-full"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="rating"
            className="block text-gray-700 font-bold mb-2"
          >
            Rating
          </label>
          <input
            type="number"
            id="rating"
            value={rating}
            onChange={handleRating}
            className="border rounded-md px-4 py-2 w-full"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="category"
            className="block text-gray-700 font-bold mb-2"
          >
            Category
          </label>
          <select
            id="category"
            value={category}
            onChange={handleCategoryChange}
            className="border rounded-md px-4 py-2 w-full"
          >
            <option value="">Select a category</option>
            {categories.map((category) => (
              <option key={category.id} value={category.name}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
              <label htmlFor="description" className="block text-gray-700 font-bold mb-2">
                Description
              </label>
              <ul>
                {editedDescription.map((desc, index) => (
                  <li key={index} className="flex items-center">
                    <input
                      type="text"
                      value={desc}
                      onChange={(event) => handleDescriptionChange(index, event)}
                      className="border rounded-md px-4 py-2 w-full"
                    />
                    <button type="button" onClick={() => handleRemoveDescription(index)}>
                      ❌
                    </button>
                  </li>
                ))}
                <li>
                  <input
                    type="text"
                    value={newDescription}
                    onChange={(event) => setNewDescription(event.target.value)}
                    className="border rounded-md px-4 py-2 w-full"
                  />
                  <button type="button" onClick={handleAddDescription}>
                    Add Description
                  </button>
                </li>
              </ul>
            </div>
        <div className="mb-4">
      <label htmlFor="images" className="block text-gray-700 font-bold mb-2">
        Uploaded Images
      </label>
      <div className="flex flex-wrap -mx-4">
  {downloadUrls.map((image, index) => (
    <div key={index} className="relative w-24 h-24 mx-4 my-2">
    <button
      type="button"
      className="absolute top-0 right-0 text-gray-700 hover:text-red-500"
      onClick={() => handleImageRemove(index)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-4 w-4"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M6.293 6.293a1 1 0 011.414 0L10 8.586l2.293-2.293a1 1 0 011.414 1.414L11.414 10l2.293 2.293a1 1 0 01-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 01-1.414-1.414L8.586 10 6.293 7.707a1 1 0 010-1.414z"
          clipRule="evenodd"
        />
      </svg>
    </button>
    <img
      src={image}
      alt=""
      className="w-full h-full object-cover"
    />
  </div>
  ))}
</div>


    </div>


        <div className="mb-4">
          <label
            htmlFor="images"
            className="block text-gray-700 font-bold mb-2"
          >
           Upload Images
          </label>
          <input
            type="file"
            id="images"
            accept="image/*"
            onChange={handleImageChange}
            multiple
            className="hidden"
          />
          <div className="flex flex-wrap -mx-4">
            {images.map((image, index) => (
              <div key={index} className="relative w-24 h-24 mx-4 my-2">
                <button
                  type="button"
                  className="absolute top-0 right-0 text-gray-700 hover:text-red-500"
                  onClick={() => handleImageCancel(index)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M6.293 6.293a1 1 0 011.414 0L10 8.586l2.293-2.293a1 1 0 011.414 1.414L11.414 10l2.293 2.293a1 1 0 01-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 01-1.414-1.414L8.586 10 6.293 7.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                <img
                  src={URL.createObjectURL(image)}
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            ))}
            {images.length < 4 && (
              <div className="relative m-2">
                <label
                  htmlFor="image"
                  className="h-24 w-24 rounded-md border-dashed border-2 border-gray-400 flex items-center justify-center cursor-pointer"
                >
                  <input
                    type="file"
                    id="image"
                    onChange={handleImageChange}
                    className="sr-only"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10 text-gray-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M15 8a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1V9a1 1 0 011-1h1V6a2 2 0 012-2h4a2 2 0 012 2v1h1zm-7-2a1 1 0 00-1 1v1h2V7a1 1 0 00-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                </label>
              </div>
            )}
             {/* {uploadProgress > 0 && (
              <div className="mt-2">
                <progress className="w-full h-2" value={uploadProgress} max="100" />
              </div>
            )} */}
            {images.length > 0 && (
              <button
                onClick={handleUpload}
                className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                {uploadProgress}
              </button>
            )}
            {success && <div className="text-green-500">{downloadUrls.length} Images uploaded successfully!</div>}
            {error && <div className="text-red-500">Error: {error}</div>}
          </div>
        </div>
        {successAdd && <div className="text-green-500">{msg}</div>}

        <div className="flex justify-end">
          <button
            type="button"
            className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 mr-2"
            onClick={() => setShowModal(false)}
          >
            Cancel / Close
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
          >
            Save
          </button>

        </div>
      </form>
    </div>
  </div>
</div>

  );

}

           

export default EditProductModal;
