import  {  createSlice} from '@reduxjs/toolkit'


const scrollSlice = createSlice({
    name: "Scroll",
    initialState: {
       scrollx: 0
    },
    reducers: {
        storeScroll: (state,action) => {
            state.value= action.payload
        }
    }
})

export const  { storeScroll } = scrollSlice.actions

export default scrollSlice.reducer


