import Slider from "../components/App/Slider";
import ContactUsContent from "../components/Contact/ContactContent";
import Footer from "../components/common/Footer";
import Header from "../components/common/Header";

function Contact() {
  return (
    <div className="App">
        <Header></Header>
        <Slider></Slider>
        <ContactUsContent></ContactUsContent>
        <Footer></Footer>
    </div>
  );
}

export default Contact;
