import { useEffect, useState } from "react";
import { getAuth } from 'firebase/auth';
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import MyOrders from "../components/MyOrder/MyOrders";


const auth = getAuth();

function MyOrderPage() {
  
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [userId, setUserId] = useState("");
  
  

  useEffect(() => {
    // Check if the user is already logged in
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const currentUser = auth.currentUser;
        const userId = currentUser.uid;
        setUserId(userId);
        console.log(userId);
        setLoggedIn(true);

      } else {
        setLoggedIn(false);
      }
    });

    // Cleanup the auth state change listener
    return () => unsubscribe();
  }, []);



  return (
    <>
    <Header></Header>
    <MyOrders isLoggedIn={isLoggedIn} userId={userId}></MyOrders>
    <Footer></Footer>
    </>
  );
}

export default MyOrderPage;
