import React, { useEffect, useState } from 'react';
import { useCart } from '../../cookies/CartProvider';
import { getFirestore, doc, getDoc, updateDoc, onSnapshot } from 'firebase/firestore';

const db = getFirestore();
const CartLayout = ({serviceCharge,TranspostationCharge}) => {
  console.log(serviceCharge)
  const [formattedCartItems, setFormattedCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalSavings, setTotalSavings] = useState(0);
  const [subTotal,setSubTotal ] = useState(0);
  
  const { cartItems, addToCart, removeFromCart, clearCart, onIncrement, onDecrement } = useCart();

 
  
  const priceFormat = (price) =>{
    price = Number(price);
    return price.toLocaleString();
  }

  useEffect(() => {
    setTotalPrice(subTotal+serviceCharge+TranspostationCharge);

  },[subTotal,serviceCharge,TranspostationCharge])

  useEffect(() => {
    const firestore = getFirestore();

    // Function to fetch the product details for a single cart item
    const getProductDetails = async (cartItem) => {
      console.log(cartItem);
      try {
        const productRef = doc(firestore, 'products', cartItem.id);
        const productSnap = await getDoc(productRef);
        if (productSnap.exists()) {
          return { ...productSnap.data(), quantity: cartItem.quantity, id: cartItem }; 
        }
        return null;
      } catch (error) {
        console.error('Error fetching product details:', error);
        return null;
      }
    };

    // Function to fetch product details for all cart items and update the state
    const updateCartItems = async () => {
      const formattedItems = [];
      let total = 0;
      let savings = 0;
      let subTotal = 0;

      for (const cartItem of cartItems) {
        const productDetails = await getProductDetails(cartItem);
        if (productDetails) {
          formattedItems.push(productDetails);
          total += Number(productDetails.price) * productDetails.quantity ;
          subTotal = total

          total = total +serviceCharge+ TranspostationCharge // Convert productDetails.price to a number
          savings += (productDetails.originalPrice - productDetails.price)* productDetails.quantity;
        }
      }

      setFormattedCartItems(formattedItems);
      setSubTotal(subTotal)
      setTotalSavings(savings);
    };

    // Fetch initial product details for cart items
    updateCartItems();
  }, [cartItems]);

  const handleRemoveItem = (productId) => {
    removeFromCart(productId)
  };

  const handleIncrement = (productId) => {
    onIncrement(productId);
  };

  const handleDecrement = (productId) => {
    onDecrement(productId);
  };


  const formatNumber = (number) => {
    return Number(number).toFixed(2);
  };

  useEffect(() => {
    console.log(formattedCartItems); // This will reflect the updated state
  }, [formattedCartItems]);

  if (formattedCartItems.length === 0) {
    return (
      <div className="mx-auto my-auto flex items-center justify-center flex-col h-full">
  {/* <svg
    xmlns="http://www.w3.org/2000/svg"
    className="svg-icon w-12 h-12 text-white"
    viewBox="0 0 1024 1024"
    version="1.1"
  >
<path d="M576 469.333333a21.333333 21.333333 0 0 1-21.333333-21.333333v-298.666667a21.333333 21.333333 0 0 1 42.666666 0v298.666667a21.333333 21.333333 0 0 1-21.333333 21.333333z"/><path d="M448 298.666667a21.333333 21.333333 0 0 1-15.104-36.437334l128-128a21.333333 21.333333 0 0 1 30.165333 30.165334l-128 128A21.248 21.248 0 0 1 448 298.666667z"/><path d="M704 298.666667a21.248 21.248 0 0 1-15.104-6.229334l-128-128a21.333333 21.333333 0 0 1 30.165333-30.165333l128 128A21.333333 21.333333 0 0 1 704 298.666667zM384 917.333333a106.666667 106.666667 0 1 1 106.666667-106.666666A106.752 106.752 0 0 1 384 917.333333z m0-170.666666a64 64 0 1 0 64 64A64.085333 64.085333 0 0 0 384 746.666667zM725.333333 917.333333a106.666667 106.666667 0 1 1 106.666667-106.666666 106.752 106.752 0 0 1-106.666667 106.666666z m0-170.666666a64 64 0 1 0 64 64 64.085333 64.085333 0 0 0-64-64z"/><path d="M320 682.666667a21.333333 21.333333 0 0 1-21.333333-21.333334C298.666667 315.52 225.408 212.906667 181.76 182.485333a76.117333 76.117333 0 0 0-67.413333-13.226666 21.632 21.632 0 0 1-27.477334-12.288 21.248 21.248 0 0 1 11.861334-27.434667 118.058667 118.058667 0 0 1 105.813333 16.896c88.234667 59.733333 134.186667 225.109333 136.661333 491.733333l459.648-38.272a21.504 21.504 0 0 0 18.730667-15.445333l62.634667-261.845333a21.077333 21.077333 0 0 1 25.685333-15.786667 21.333333 21.333333 0 0 1 15.786667 25.728l-62.848 262.741333a64.64 64.64 0 0 1-56.405334 47.104l-482.645333 40.192L320 682.666667z"/>      </svg> */}
<p className="text-black font-semibold mt-2">Your cart is empty.</p>
</div>
    );
  }


  return (
    <div >

   
      {formattedCartItems.map((product) => (
        <div
          key={product.id}
          className="flex flex-col bg-gray-100 w-full px-4 py-2"
        >

          

          <div className="flex items-center">
            <img
              src={product.downloadUrls[0]}
              alt={product.name}
              className="h-12 w-12 rounded-full object-cover"
            />
            <div className="ml-3">
              <p className="text-gray-700 font-semibold">{product.name}</p>
              <p className="text-gray-500">Price: ₹{priceFormat(product.price*product.quantity)}</p>
              <div className="flex items-center">
              <button
                onClick={() =>handleDecrement(product.id)}
                disabled={product.quantity === 1}
                className="bg-gray-300 text-gray-500 font-semibold py-1 px-2 rounded-md"
              >
                -
              </button>
              <p className="text-gray-500 mx-2">{product.quantity}</p>
              <button
                onClick={() => handleIncrement(product.id)}
                className="bg-blue-500 text-white font-semibold py-1 px-2 rounded-md"
              >
                +
              </button>
            </div>
            </div>
          </div>
          <button onClick={() => handleRemoveItem(product.id)} className="text-red-500 font-semibold">Remove</button>
        </div>
      ))}
      <div className="bg-gray-100 px-4 py-2 border-t-2 pt-10">
      <div className="flex font-semibold justify-between">
        <p>Item(s) Total (Inclusive of taxes)</p>
        <p>₹{priceFormat(subTotal)}</p>
      </div>
      <div className="flex text-green-500 font-semibold justify-between">
        <p>Discount</p>
        <p>₹{priceFormat(totalSavings)}</p>
      </div>
        <div className="flex font-semibold justify-between">
          <p>Packing Charge</p>
          <p
          className={serviceCharge?"":"flex text-green-500 font-semibold justify-between"}
          >{serviceCharge?`₹${serviceCharge}`:`Free`}</p>
        </div>
        <div className="flex font-semibold justify-between">
          <p>Delivery</p>
          <p
          className={TranspostationCharge?"":"flex text-green-500 font-semibold justify-between"}
          >{TranspostationCharge?`₹${TranspostationCharge}`:`Free`}</p>
        </div>
        <div className="flex font-semibold justify-between border-t-2 mt-2 pt-2">
          <p>Order Total</p>
          <p>₹{priceFormat(totalPrice)}</p>
        </div>
      </div>
    </div>
  );
};

export default CartLayout;
