// CartContext.js
import { createContext, useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';

const CartContext = createContext();

const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);

  // Load cart items from cookies on initial render
  useEffect(() => {
    const cartData = Cookies.get('cart');
    if (cartData) {
      setCartItems(JSON.parse(cartData));
    }
  }, []);

  // Save cart items to cookies whenever the cartItems state changes
  useEffect(() => {
    Cookies.set('cart', JSON.stringify(cartItems));
  }, [cartItems]);

  // Add item to cart
  const addToCart = (item) => {
    // Check if the item is already in the cart
    const existingItem = cartItems.find((cartItem) => cartItem.id === item);

    if (existingItem) {
      // If the item already exists, update its quantity
      const updatedCartItems = cartItems.map((cartItem) =>
        cartItem.id === item ? { ...cartItem, quantity: cartItem.quantity + 1 } : cartItem
      );
      setCartItems(updatedCartItems);
    } else {
      // If the item is not in the cart, add it with a quantity of 1
      setCartItems((prevItems) => [...prevItems, { id: item, quantity: 1 }]);
    }
  };

  // Remove item from cart
  const removeFromCart = (itemToRemove) => {
    const updatedCartItems = cartItems.filter((item) => item.id !== itemToRemove.id);
    setCartItems(updatedCartItems);
  };

  const onIncrement = (itemIncre) => {
    console.log("onincre");

    const updatedCartItems = cartItems.map((item) => {
      if (item.id == itemIncre.id) {
        console.log("onincre");

        return { ...item, quantity: item.quantity + 1 };
      } else {
        return item;
      }
    });
    setCartItems(updatedCartItems);
  };

  const onDecrement = (itemDecrem) => {
    const updatedCartItems = cartItems.map((item) => {
      if (item.id === itemDecrem.id) {
        if(item.quantity>1){
          return { ...item, quantity: item.quantity - 1 };
        }else{
          return item
        }
      } else {
        return item;
      }
    });
    setCartItems(updatedCartItems);
  };

  // Clear cart
  const clearCart = () => {
    setCartItems([]);
  };

  return (
    <CartContext.Provider value={{ cartItems, addToCart, removeFromCart, clearCart, onIncrement, onDecrement }}>
      {children}
    </CartContext.Provider>
  );
};

// Custom hook to consume the cart context
const useCart = () => useContext(CartContext);

export { CartProvider, useCart };
