import React, { useEffect } from 'react';

const OrderSuccessMessage = ({orderId}) => {

  const handleButtonClick = () => {
    window.location.href = '/';
  };

  return (
    <div
      className="flex flex-col items-center justify-center bg-gray-100"
      style={{ height: '80vh' }}
    >
      <div className="flex flex-col items-center justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="w-16 h-16 text-green-500 mx-auto mb-4"
        >
          <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
          <polyline points="22 4 12 14.01 9 11.01"></polyline>
        </svg>
        <h2 className="text-2xl font-bold mb-4">Order Placed Successfully</h2>
        <div className='text-sm px-8'>
          <p>We have received your order.The order id is <strong>{orderId}</strong> You will get notified when product is delivered.</p>

        </div>
        <button
          onClick={handleButtonClick}
          className="mt-8 px-6 py-3 bg-indigo-500 text-white rounded-lg font-semibold"
        >
          Go Home
        </button>
      </div>
    </div>
  );
};

export default OrderSuccessMessage;
