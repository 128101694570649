// AdminHome.js

import React, { useEffect, useLayoutEffect, useState } from "react";
import SidePanel from "./Components/SidePanel";
import AdminHeader from "./Components/Header";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import AdminSlider from "./Components/SliderAdmin";
import DashboardCard from "./Components/DashboardCards";
import { get, getDatabase, ref as databaseRef } from "firebase/database";
import { ref } from "firebase/storage";

const AdminHome = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [webStats, setWebStats] = useState([]);



  useEffect(() => {
    const checkAdminStatus = (user) => {
      if (user && user.email === 'sproutive.in@gmail.com' || "manuvini29@gmail.com") {
        setLoggedIn(true);
      } else {
        window.location.href = '/admin';
      }
    };

    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      checkAdminStatus(user);
    });

    return () => unsubscribe();
  }, []);

  const getCurrentDateFormatted = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    return `${year}${month}${day}`;
  };
  


  useEffect(() => {
    const db = getDatabase();
    const dbRef = databaseRef(db, "stats" )
    get(dbRef).then((snapshot) => {
      const data = snapshot.val();
      const formatdata = Object.keys(data).map((key,index) =>{
        return{
          key: key,
          val: data[key],
          id: index+1

        }
      })

      console.log(formatdata);
      setWebStats(formatdata);
    })
  },[])


  
  if (!loggedIn) {
    return null; // or return a loading/spinner component
  }

  // Array of random Tailwind CSS background color classes

  return (
    <div className="flex h-screen">
      <SidePanel />
      <div className="flex-1 ml-56">
        <AdminHeader />
        <div className="p-6">
          <h2 className="text-2xl font-bold mb-4">Dashboard</h2>
        </div>

        <div className="grid grid-cols-4 gap-4 p-6">
          {
            webStats.map((stat, index) => {
              return <DashboardCard key={index} title={stat.key} value={stat.val} />;
            })
          }
         
        </div>
      </div>
    </div>
  );
};

export default AdminHome;
