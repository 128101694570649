import React, { useState, useEffect } from 'react';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import Loading from '../common/Loading';

const MyOrders = ({ isLoggedIn , userId }) => {
  const [orders, setOrders] = useState([]);



  useEffect(() => {
    if (isLoggedIn) {

      const getUserOrders = async () => {
        try {
          const firestoreDb = getFirestore();
          const currentDate = new Date();
          const currentYear = currentDate.getFullYear().toString();
          const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0'); // Adding 1 to get 1-based month and padding with '0'
          
          console.log(currentYear)
          // Create a reference to the orders collection with the current year and month
          const ordersCollectionRef = collection(firestoreDb, 'orders', currentYear, currentMonth);         
          const queryRef = query(ordersCollectionRef, where('userId', '==', userId));
          const querySnapshot = await getDocs(queryRef);
      
          const userOrders = querySnapshot.docs.map((doc) => doc.data());
          console.log('User orders:', userOrders , userId);
          setOrders(userOrders);
        } catch (error) {
          console.error('Error retrieving user orders:', error);
        }
      }

      getUserOrders();
    }
  }, [isLoggedIn]);

  const formatDate = (timestamp) => {
    const date = timestamp.toDate();
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };


  if (!isLoggedIn) {
    return <> <Loading></Loading></>
  }
  if(orders.length==0){
    return <>
    <div className="flex items-center justify-center h-screen">
      <h1 className='text-2xl'>You Have Zero Orders</h1>
    </div>
    
    </>
  }

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-semibold mb-4">My Orders</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {orders.map((order) => (
          <div key={order.orderId} className="bg-white shadow-md p-4 rounded-lg">
                <h3 className="text-lg font-semibold mb-2">Order #{order.orderId}</h3>
                {order.productsData.map((product, index) => (
                  <div key={index} className="mb-4">
                    <p className="text-gray-600 mb-2">Product Name: {product.productName}</p>
                    <p className="text-gray-600 mb-2">Total: ₹{product.productPrice}</p>
                    <img
                    src={product.productImage}
                    alt={`Order ${order.orderId}`}
                    className="h-60 md:h-80 lg:h-96 w-full object-cover mb-2 rounded-lg"
                  />
                  </div>
                ))}
                <p className="text-gray-600 mb-2">Address: {order.apartment} {order.address} {order.city} {order.postalCode}</p>
                <p className="text-gray-600">Status: {order.status}</p>
                <p className="text-gray-600">Order Total: ₹{order.orderTotal}</p>

            {/* <p className="text-gray-600">Name: {order.name}</p>
            <p className="text-gray-600">Phone Number: {order.phoneNumber}</p> */}



          </div>
        ))}
      </div>
    </div>
  );
};

export default MyOrders;
