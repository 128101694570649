import { useState, useEffect } from "react";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { get, ref, set, update } from "firebase/database";
import { onValue, getDatabase } from "firebase/database";
import firebaseConfig from "../../../firebase/firebaseConfig";
import Alert from "../common/Alert";
import axios from "axios";
import Button from "react-bootstrap-button-loader";
import {
  getFirestore,
  collection,
  doc,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { GoogleLoginButton } from "../common/GoogleBtn";

function generateOrderID() {
  const length = 8; // Length of the order ID
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"; // Characters to choose from
  let orderID = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    orderID += characters.charAt(randomIndex);
  }

  return orderID;
}

const currentYear = new Date().getFullYear();
const currentMonth = String(new Date().getMonth() + 1).padStart(2, "0");

const Checkout = ({ cartId }) => {
  const orderId = generateOrderID();
  const [cartID, setcartID] = useState(cartId);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [apartment, setApartment] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [alternateNumber, setAlternateNumber] = useState("");
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("cod");
  const [loggedIn, setLoggedIn] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [productImage, setProductImage] = useState("");
  const [productPrice, setProductPrice] = useState(699);
  const [productOriginalPrice, setProductOriginalPrice] = useState(0);
  const [productName, setProductName] = useState("");
  const [error, setError] = useState("");
  const [productKey, setProductKey] = useState("");
  const [loadingBtn, setloadingBtn] = useState(false);
  const [products, setProducts] = useState([]);
  const [showUpi, setShowUpi] = useState(false);
  const [userId, setUserId] = useState("");
  const [orderTotal, setOrderTotal] = useState(0);
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [serviceCharge, setServiceCharge] = useState(0);
  const [subtotal,setsubtoal] = useState(0)
  const [hasFoodAllergy, setHasFoodAllergy] = useState(false);
  const [foodAllergyInfo, setFoodAllergyInfo] = useState('');


  const fetchLocationData = async (countryCode, postalCode) => {
    try {
      const response = await axios.get(
        `https://api.worldpostallocations.com/pincode?postalcode=${postalCode}&countrycode=${countryCode}`
      );
      return response;
    } catch (error) {
      console.error("Error fetching location data:", error);
      return null;
    }
  };

  const handleClickInputStateCountry = () => {
    console.log("listening");
    if (postalCode.length === 6) {
      // Assuming postal codes are always 6 characters long
      // You may need to validate the format according to your country's postal code format
      fetchLocationData("IN", postalCode)
        .then((data) => {
          console.log(data);
          if (data) {
            if (data.data.result[0].state != state) {
              setState(data.data.result[0].state); // Update the state with the fetched state value
              setCountry(data.data.result[0].country); // Update the country with the fetched country value
            }
          } else {
            setState(""); // Clear the state if data is not found
            setCountry(""); // Clear the country if data is not found
          }
        })
        .catch((error) => {
          console.error("Error fetching location data:", error);
        });
    } else {
      setState(""); // Clear the state when postal code is cleared or invalid
      setCountry(""); // Clear the country when postal code is cleared or invalid
    }
  };

  const handlePostalCodeChange = (e) => {
    const newPostalCode = e.target.value;
    setPostalCode(newPostalCode);

    if (newPostalCode.length === 6) {
      // Assuming postal codes are always 6 characters long
      // You may need to validate the format according to your country's postal code format
      fetchLocationData("IN", newPostalCode)
        .then((data) => {
          console.log(data);
          if (data) {
            if (data.data.result[0].state != state) {
              
              setState(data.data.result[0].state); // Update the state with the fetched state value
              setCountry(data.data.result[0].country); // Update the country with the fetched country value
            } // Update the country with the fetched country value
          } else {
            setState(""); // Clear the state if data is not found
            setCountry(""); // Clear the country if data is not found
          }
        })        .catch((error) => {
          console.error("Error fetching location data:", error);
        });
    } else {
      setState(""); // Clear the state when postal code is cleared or invalid
      setCountry(""); // Clear the country when postal code is cleared or invalid
    }
  };

  useEffect(() => {
    // Check if the user is already logged in
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setLoggedIn(true);
        setUserEmail(user.email);
        const currentUser = auth.currentUser;
        const userId = currentUser.uid;
        setUserId(userId);
      } else {
        setLoggedIn(false);
        setUserEmail("");
      }
    });

    // Cleanup the auth state change listener
    return () => unsubscribe();
  }, []);


  useEffect(() => {
    if (postalCode!= null && postalCode.length === 6) {
      const firestoreDB = getFirestore();
      
      // Use getDoc to retrieve a document
      const docRef = doc(firestoreDB, "zipcode", postalCode); // Replace 'yourCollectionName' with your actual collection name
  
      getDoc(docRef)
        .then((docSnapshot) => {
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            
            setServiceCharge(data.service);
            setDeliveryCharge(data.transportation);
            setOrderTotal(subtotal+serviceCharge+deliveryCharge)

            // Handle the retrieved data here
            console.log(data);
          } else {
            console.log('Document not found');
            setPostalCode(null)
          }
        })
        .catch((error) => {
          console.error('Error fetching document:', error);
        });
    }
  }, [postalCode,subtotal]);
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dbRef = ref(getDatabase(), "carts/" + cartID);

        // Create an empty array to store the product IDs
        const productIDs = [];
        var orderTot = 0;

        // Attach the 'onValue' event listener to fetch the cart data
        onValue(
          dbRef,
          (snapshot) => {
            const cartData = snapshot.val();

            if (cartData && Array.isArray(cartData)) {
              const firestore = getFirestore(); // Get the Firestore instance
              console.log(cartData)
              setPostalCode(cartData[0].zipcode)
              setServiceCharge(cartData[0].service);
              setDeliveryCharge(cartData[0].transporatation)
              

              // Prepare an array of Promises to fetch product data for each item in the cart
              const productPromises = cartData
                .filter((item) => item.productId) // Filter out items without a productId
                .map((item) => {
                  const productDocRef = doc(
                    firestore,
                    "products",
                    item.productId
                  );
                  return getDoc(productDocRef).then((snapshot) => {
                    if (snapshot.exists()) {
                      const productData = snapshot.data();
                      return formatProductData(productData, item.quantity);
                    }
                  });
                });

              // Use Promise.all to wait for all product data fetching to complete
              Promise.all(productPromises)
                .then((formattedProducts) => {
                  let orderTotal = formattedProducts.reduce(
                    (total, product) =>
                      total + Number(product.price) * product.quantity,
                    0
                  );
                  setsubtoal(orderTotal);
                  setProducts(formattedProducts);
                })
                .catch((error) => {
                  console.error("Error fetching product data:", error);
                });
            } else {
              console.log("Cart data not found or invalid format.");
            }
          },
          (error) => {
            console.error("Error fetching cart data:", error);
          }
        );

        const formatProductData = (data, quantity) => {
          const formattedProduct = {
            id: "1", // Replace with actual product ID
            name: data.name || "",
            category: data.category, // Replace with actual category
            originalPrice: data.originalPrice || 0,
            price: data.price || 0,
            images: [],
            description: Array.isArray(data.description)
              ? data.description
              : [],
            rating: data.rating || 0,
            quantity: quantity,
          };
          setProductPrice(data.price);
          setProductName(data.name);
          if (data.downloadUrls) {
            formattedProduct.images = Object.keys(data.downloadUrls).map(
              (key) => {
                const image = data.downloadUrls[key];
                return {
                  id: parseInt(key),
                  name: `Image ${key}`,
                  src: image || "",
                  alt: `Image ${key}`,
                };
              }
            );
          }

          setProductImage(formattedProduct.images[0].src);

          return formattedProduct;
        };
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();

    // Clean up the listener when the component unmounts
    return () => {
      const dbRef = ref(getDatabase());
      // off(dbRef);
    };
  }, []);


  const saveOrderData = async (orderData) => {
    try {
      const firestoreDb = getFirestore();
      const orderDocRef = doc(
        firestoreDb,
        "orders",
        currentYear.toString(),
        currentMonth,
        orderData.orderId
      );
      await setDoc(orderDocRef, orderData);
      console.log(products)
      const response = await axios.post('https://b6a5qlfz80.execute-api.us-west-2.amazonaws.com/dev/',{
        tempType: "pending",
        orderId :orderId,
        userName : name,
        userEmail : userEmail,
        userPhone : phoneNumber,
        userAddress : address + " "+ apartment + " "+ city +" "+ postalCode,
        productImage : productImage,
        products : orderData.productsData,
        subtotal,
        orderTotal,
        serviceCharge,
        deliveryCharge,
        foodAllergyInfo
    });
      console.log("Order data saved to Firestore successfully!");
    } catch (error) {
      console.error("Error saving order data:", error);
    }
  };

  const handleFormSubmit = async () => {
    console.log("submitted");

    // Check if any required fields are empty
    if (
      !phoneNumber ||
      !userEmail ||
      !name ||
      !address ||
      !city ||
      !postalCode ||
      !selectedPaymentMethod
    ) {
      setError("Please fill in all required fields.");
      setTimeout(() => {
        setError("");
      }, 2000);
      return;
    }

    setloadingBtn(true);
    const productsData = [];
    products.forEach((product) =>{
      const oneProduct = {
        productName : product.name,
        productImage : product.images[0].src,
        productPrice : product.price ,
        productQuantity :  product.quantity
      }
      productsData.push(oneProduct);
    })
    const orderTimestamp = Date.now();

    const orderData = {
      name,
      phoneNumber,
      alternateNumber,
      userEmail,
      address,
      apartment,
      city,
      postalCode,
      serviceCharge,
      deliveryCharge,
      status: "pending",
      productsData,
      subtotal,
      orderTotal,
      selectedPaymentMethod,
      orderTimestamp,
      orderId,
      userId,
      state,
      country,
      orderMonth: currentMonth,
      orderYear: currentYear.toString(),
    };

  
    await saveOrderData(orderData);
    // const response = await axios.post(
    // "https://flamesrazrorpay.azurewebsites.net/api/HttpTriggerMail?code=D7Slw44GBQ9BdJkdS2O37CDubcH5Qwg01OKvAeoQNHFqAzFunN0Z8A==",
    // {
    //     email: userEmail,
    //     orderID: orderId,
    //     productName: productName,
    //     orderPrice: "" + currencySymbol + orderTotal,
    //     customerName: name,
    //     customerAddress:
    //     address +
    //     " " +
    //     apartment +
    //     " " +
    //     city +
    //     " " +
    //     state +
    //     " " +
    //     postalCode,
    // });
    window.location.href = `/orderplaced/${orderId}`;

  };

  const isMobileDevice = () => {
    const mobileBreakpoint = 640; // Set the breakpoint for mobile devices
    return window.innerWidth <= mobileBreakpoint;
  };

  var paymentMethods = [
    { id: "cod", label: "Cash on Delivery" },
    // { id: 'cod', label: 'Cash on Delivery' },
  ];

  const handleLogin = () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    // Sign in with Google popup
    signInWithPopup(auth, provider)
      .then((result) => {
        // Redirect to admin home after successful login
      })
      .catch((error) => {
        console.error("Login failed:", error);
      });
  };

  const currencySymbols = {
    INR: "₹",
    // Add more currencies and their symbols as needed
  };
  const currencySymbol = currencySymbols["INR"];

  return (
    <>
      {/* {!loggedIn &&  <LoginToConitue ></LoginToConitue> } */}
      {error && <Alert type="error" message={error} />}

      <div className="min-h-screen bg-gray-100 mt-0 mb-50 flex items-center justify-center">
        <div className="bg-grey-100">
          <div className="max-w-4xl w-full mx-4">
            <h2 className="text-2xl font-bold mt-10 mb-4">Checkout</h2>

            {!loggedIn && (
              <div className="sm:grid-cols-2 gap-8 sm:grid-cols-1">
                <div className="mb-8 sm:grid-cols-2 gap-8 sm:grid-cols-1">
                  <h3 className="text-lg font-semibold mb-2">Login</h3>
                  {/* Form fields for login */}
                  <GoogleLoginButton onClick={handleLogin}></GoogleLoginButton>
                </div>

                <div className="flex items-center mb-8 sm:grid-cols-2 gap-8 sm:grid-cols-1">
                  <hr className="flex-grow border-t-2 border-gray-300" />
                  <span className="mx-4 text-gray-500">or</span>
                  <hr className="flex-grow border-t-2 border-gray-300" />
                </div>

                <div>
                  <h3 className="text-lg font-semibold mb-2 ">
                    {" "}
                    Continue as Guest
                  </h3>
                  {/* Form fields for guest checkout */}
                </div>
              </div>
            )}

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 sm:grid-cols-1">
              <div>
                <div className=" sm:hidden">
                    <div
                      // key={index}
                      className="bg-white rounded-md p-6 shadow-md mb-8"
                      style={{ maxWidth: isMobileDevice() ? "20rem" : "none" }}
                    >
                      <h3 className="text-lg font-semibold mb-4">
                        Order Details
                      </h3>
                      {products.map((product, index) => (

                      <div className="flex items-center mb-4">
                        <img
                          src={product.images[0].src}
                          alt="Product"
                          className="w-16 h-16 rounded-md mr-4"
                        />
                        <div>
                          <p className="font-semibold">{product.name}</p>
                          <p className="font-semibold">
                            Sub Total: {currencySymbol}
                            {subtotal}
                          </p>
                          <p className="text-gray-500">
                            Price: {currencySymbol}
                            {product.price * product.quantity}
                          </p>
                          <p className="text-gray-500">
                            Quantity:
                            {product.quantity}
                          </p>
                          <p className="text-gray-500">
                            Total Savings: {currencySymbol}
                            {(product.originalPrice - product.price) *
                              product.quantity}
                          </p>
                        </div>
                        <hr className="my-8" />
                      </div>
                       ))}

                      <hr className="my-4" />
                      <p className="font-semibold">
                        Sub Total: {currencySymbol}
                        {subtotal}
                      </p>
                      <p className="font-semibold">
                        Delivery Charge: {currencySymbol}
                        {deliveryCharge}
                      </p>
                      <p className="font-semibold">
                        Packing Charge: {currencySymbol}
                        {serviceCharge}
                      </p><p className="font-semibold">
                        Order Total: {currencySymbol}
                        {orderTotal}
                      </p>
                    </div>
                </div>
                <div
                  className="bg-white rounded-md p-6 shadow-md mb-8 "
                  style={{ maxWidth: isMobileDevice() ? "20rem" : "none" }}
                >
                  <h3 className="text-lg font-semibold mb-4">
                    Contact Details
                  </h3>
                  <div className="mb-4">
                    <label htmlFor="phoneNumber" className="block mb-1">
                      Email id *
                    </label>
                    <input
                      type="email"
                      id="email"
                      value={userEmail}
                      onChange={(e) => setUserEmail(e.target.value)}
                      className="w-full border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 p-2"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="phoneNumber" className="block mb-1">
                      Phone Number *
                    </label>
                    <input
                      type="text"
                      id="phoneNumber"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      className="w-full border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 p-2"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="alternateNumber" className="block mb-1">
                      Alternate Number
                    </label>
                    <input
                      type="text"
                      id="alternateNumber"
                      value={alternateNumber}
                      onChange={(e) => setAlternateNumber(e.target.value)}
                      className="w-full border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 p-2"
                    />
                  </div>
                </div>
                <div
                  className="bg-white rounded-md p-6 shadow-md mb-8 "
                  style={{ maxWidth: isMobileDevice() ? "20rem" : "none" }}
                >
                  <h3 className="text-lg font-semibold mb-4">
                    Delivery Information
                  </h3>
                  <div className="mb-4">
                    <label htmlFor="name" className="block mb-1">
                      Name *
                    </label>
                    <input
                      type="text"
                      id="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="w-full border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 p-2"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="apartment" className="block mb-1">
                      Apartment / Suite
                    </label>
                    <input
                      type="text"
                      id="apartment"
                      value={apartment}
                      onChange={(e) => setApartment(e.target.value)}
                      className="w-full border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 p-2"
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="address" className="block mb-1">
                      Address *
                    </label>
                    <input
                      type="text"
                      id="address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      className="w-full border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 p-2"
                      required
                    />
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label htmlFor="city" className="block mb-1">
                        City *
                      </label>
                      <input
                        type="text"
                        id="city"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        className="w-full border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 p-2"
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="postalCode" className="block mb-1">
                        Postal Code *
                      </label>
                      <input
                        type="text"
                        id="postalCode"
                        value={postalCode}
                        maxLength={6}
                        disabled
                        onChange={handlePostalCodeChange}
                        className="w-full border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 p-2"
                        required
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-4 mt-4">
                    <div>
                      <label htmlFor="state" className="block mb-1">
                        State *
                      </label>
                      <input
                        type="text"
                        id="state"
                        value={state}
                        onClick={handleClickInputStateCountry}
                        onChange={(e) => setState(e.target.value)}
                        className="w-full border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 p-2"
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="country" className="block mb-1">
                        Country *
                      </label>
                      <input
                        type="text"
                        id="country"
                        value={country}
                        onClick={handleClickInputStateCountry}
                        onChange={(e) => setCountry(e.target.value)}
                        className="w-full border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 p-2"
                        required
                      />
                    </div>
                  </div>
                </div>



                <div
        className="bg-white rounded-md p-6 shadow-md mb-8"
        style={{ maxWidth: isMobileDevice() ? '20rem' : 'none' }}
      >
        <h3 className="text-lg font-semibold mb-4">Additional Information</h3>
        <div className="mb-4">
          <label htmlFor="foodAllergy" className="block mb-1">
            Add Special Instructions (Food Allergies, Salt, Ghee, etc.)
          </label>
          <input
            type="checkbox"
            id="foodAllergy"
            checked={hasFoodAllergy}
            onChange={() => setHasFoodAllergy(!hasFoodAllergy)}
            className="mr-2"
          />
          <span className="text-gray-500">Yes</span>
        </div>
        {hasFoodAllergy && (
          <div className="mb-4">
            <label htmlFor="foodAllergyInfo" className="block mb-1">
              Special Instructions:
            </label>
            <input
              type="text"
              id="foodAllergyInfo"
              value={foodAllergyInfo}
              onChange={(e) => setFoodAllergyInfo(e.target.value)}
              className="w-full border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 p-2"
            />
          </div>
        )}
      </div>









                <div
                  className="bg-white rounded-md p-6 shadow-md mb-2 "
                  style={{ maxWidth: isMobileDevice() ? "20rem" : "none" }}
                >
                  <h3 className="text-lg font-semibold mb-4">
                    Payment Details
                  </h3>
                  <div className="flex items-center space-x-4">
                    {paymentMethods.map((method) => (
                      <label
                        key={method.id}
                        htmlFor={method.id}
                        className="flex items-center"
                      >
                        <input
                          type="radio"
                          id={method.id}
                          value={method.id}
                          checked={selectedPaymentMethod === method.id}
                          onChange={() => setSelectedPaymentMethod(method.id)}
                          className="mr-2"
                          required
                        />
                        {method.label}
                      </label>
                    ))}
                  </div>

                  <hr className="my-4" />

                  <h3 className="text-lg font-semibold mb-4">
                    Accepted Payment Methods
                  </h3>
                  <div className="flex items-center space-x-4">
                    <svg
                      class="icon icon--full-color"
                      viewBox="0 0 38 24"
                      xmlns="http://www.w3.org/2000/svg"
                      width="38"
                      height="24"
                      role="img"
                      aria-labelledby="pi-paypal"
                    >
                      <title id="pi-paypal">PayPal</title>
                      <path
                        opacity=".07"
                        d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"
                      ></path>
                      <path
                        fill="#fff"
                        d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"
                      ></path>
                      <path
                        fill="#003087"
                        d="M23.9 8.3c.2-1 0-1.7-.6-2.3-.6-.7-1.7-1-3.1-1h-4.1c-.3 0-.5.2-.6.5L14 15.6c0 .2.1.4.3.4H17l.4-3.4 1.8-2.2 4.7-2.1z"
                      ></path>
                      <path
                        fill="#3086C8"
                        d="M23.9 8.3l-.2.2c-.5 2.8-2.2 3.8-4.6 3.8H18c-.3 0-.5.2-.6.5l-.6 3.9-.2 1c0 .2.1.4.3.4H19c.3 0 .5-.2.5-.4v-.1l.4-2.4v-.1c0-.2.3-.4.5-.4h.3c2.1 0 3.7-.8 4.1-3.2.2-1 .1-1.8-.4-2.4-.1-.5-.3-.7-.5-.8z"
                      ></path>
                      <path
                        fill="#012169"
                        d="M23.3 8.1c-.1-.1-.2-.1-.3-.1-.1 0-.2 0-.3-.1-.3-.1-.7-.1-1.1-.1h-3c-.1 0-.2 0-.2.1-.2.1-.3.2-.3.4l-.7 4.4v.1c0-.3.3-.5.6-.5h1.3c2.5 0 4.1-1 4.6-3.8v-.2c-.1-.1-.3-.2-.5-.2h-.1z"
                      ></path>
                    </svg>
                    <svg
                      class="icon icon--full-color"
                      viewBox="0 0 38 24"
                      xmlns="http://www.w3.org/2000/svg"
                      role="img"
                      width="38"
                      height="24"
                      aria-labelledby="pi-master"
                    >
                      <title id="pi-master">Mastercard</title>
                      <path
                        opacity=".07"
                        d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"
                      ></path>
                      <path
                        fill="#fff"
                        d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"
                      ></path>
                      <circle fill="#EB001B" cx="15" cy="12" r="7"></circle>
                      <circle fill="#F79E1B" cx="23" cy="12" r="7"></circle>
                      <path
                        fill="#FF5F00"
                        d="M22 12c0-2.4-1.2-4.5-3-5.7-1.8 1.3-3 3.4-3 5.7s1.2 4.5 3 5.7c1.8-1.2 3-3.3 3-5.7z"
                      ></path>
                    </svg>
                    <svg
                      class="icon icon--full-color"
                      viewBox="0 0 38 24"
                      xmlns="http://www.w3.org/2000/svg"
                      role="img"
                      width="38"
                      height="24"
                      aria-labelledby="pi-visa"
                    >
                      <title id="pi-visa">Visa</title>
                      <path
                        opacity=".07"
                        d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"
                      ></path>
                      <path
                        fill="#fff"
                        d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"
                      ></path>
                      <path
                        d="M28.3 10.1H28c-.4 1-.7 1.5-1 3h1.9c-.3-1.5-.3-2.2-.6-3zm2.9 5.9h-1.7c-.1 0-.1 0-.2-.1l-.2-.9-.1-.2h-2.4c-.1 0-.2 0-.2.2l-.3.9c0 .1-.1.1-.1.1h-2.1l.2-.5L27 8.7c0-.5.3-.7.8-.7h1.5c.1 0 .2 0 .2.2l1.4 6.5c.1.4.2.7.2 1.1.1.1.1.1.1.2zm-13.4-.3l.4-1.8c.1 0 .2.1.2.1.7.3 1.4.5 2.1.4.2 0 .5-.1.7-.2.5-.2.5-.7.1-1.1-.2-.2-.5-.3-.8-.5-.4-.2-.8-.4-1.1-.7-1.2-1-.8-2.4-.1-3.1.6-.4.9-.8 1.7-.8 1.2 0 2.5 0 3.1.2h.1c-.1.6-.2 1.1-.4 1.7-.5-.2-1-.4-1.5-.4-.3 0-.6 0-.9.1-.2 0-.3.1-.4.2-.2.2-.2.5 0 .7l.5.4c.4.2.8.4 1.1.6.5.3 1 .8 1.1 1.4.2.9-.1 1.7-.9 2.3-.5.4-.7.6-1.4.6-1.4 0-2.5.1-3.4-.2-.1.2-.1.2-.2.1zm-3.5.3c.1-.7.1-.7.2-1 .5-2.2 1-4.5 1.4-6.7.1-.2.1-.3.3-.3H18c-.2 1.2-.4 2.1-.7 3.2-.3 1.5-.6 3-1 4.5 0 .2-.1.2-.3.2M5 8.2c0-.1.2-.2.3-.2h3.4c.5 0 .9.3 1 .8l.9 4.4c0 .1 0 .1.1.2 0-.1.1-.1.1-.1l2.1-5.1c-.1-.1 0-.2.1-.2h2.1c0 .1 0 .1-.1.2l-3.1 7.3c-.1.2-.1.3-.2.4-.1.1-.3 0-.5 0H9.7c-.1 0-.2 0-.2-.2L7.9 9.5c-.2-.2-.5-.5-.9-.6-.6-.3-1.7-.5-1.9-.5L5 8.2z"
                        fill="#142688"
                      ></path>
                    </svg>
                    <svg
                      class="icon icon--full-color"
                      xmlns="http://www.w3.org/2000/svg"
                      role="img"
                      viewBox="0 0 38 24"
                      width="38"
                      height="24"
                      aria-labelledby="pi-american_express"
                    >
                      <title id="pi-american_express">American Express</title>
                      <g fill="none">
                        <path
                          fill="#000"
                          d="M35,0 L3,0 C1.3,0 0,1.3 0,3 L0,21 C0,22.7 1.4,24 3,24 L35,24 C36.7,24 38,22.7 38,21 L38,3 C38,1.3 36.6,0 35,0 Z"
                          opacity=".07"
                        ></path>
                        <path
                          fill="#006FCF"
                          d="M35,1 C36.1,1 37,1.9 37,3 L37,21 C37,22.1 36.1,23 35,23 L3,23 C1.9,23 1,22.1 1,21 L1,3 C1,1.9 1.9,1 3,1 L35,1"
                        ></path>
                        <path
                          fill="#FFF"
                          d="M8.971,10.268 L9.745,12.144 L8.203,12.144 L8.971,10.268 Z M25.046,10.346 L22.069,10.346 L22.069,11.173 L24.998,11.173 L24.998,12.412 L22.075,12.412 L22.075,13.334 L25.052,13.334 L25.052,14.073 L27.129,11.828 L25.052,9.488 L25.046,10.346 L25.046,10.346 Z M10.983,8.006 L14.978,8.006 L15.865,9.941 L16.687,8 L27.057,8 L28.135,9.19 L29.25,8 L34.013,8 L30.494,11.852 L33.977,15.68 L29.143,15.68 L28.065,14.49 L26.94,15.68 L10.03,15.68 L9.536,14.49 L8.406,14.49 L7.911,15.68 L4,15.68 L7.286,8 L10.716,8 L10.983,8.006 Z M19.646,9.084 L17.407,9.084 L15.907,12.62 L14.282,9.084 L12.06,9.084 L12.06,13.894 L10,9.084 L8.007,9.084 L5.625,14.596 L7.18,14.596 L7.674,13.406 L10.27,13.406 L10.764,14.596 L13.484,14.596 L13.484,10.661 L15.235,14.602 L16.425,14.602 L18.165,10.673 L18.165,14.603 L19.623,14.603 L19.647,9.083 L19.646,9.084 Z M28.986,11.852 L31.517,9.084 L29.695,9.084 L28.094,10.81 L26.546,9.084 L20.652,9.084 L20.652,14.602 L26.462,14.602 L28.076,12.864 L29.624,14.602 L31.499,14.602 L28.987,11.852 L28.986,11.852 Z"
                        ></path>
                      </g>
                    </svg>
                  </div>
                  <p className="text-gray-500 my-4 text-sm sm:text-base">
                    Your payment will be 100% secure and processed through
                    Razorpay/Paypal.
                  </p>
                </div>
              </div>
              <div>
                  <div
                    className="bg-white rounded-md p-6 shadow-md mb-8"
                    style={{ maxWidth: isMobileDevice() ? "20rem" : "none" }}
                  >
                    <h3 className="text-lg font-semibold mb-4">
                      Order Details
                    </h3>
                    {products.map((product, index) => (
                    <div className="flex items-center mb-4">
                    <img
                        src={product.images[0].src}
                        alt="Product"
                        className="w-16 h-16 rounded-md mr-4"
                    />
                    <div>
                        <p className="font-semibold">{product.name}</p>
                        <p className="text-gray-500">
                        Price: {currencySymbol}
                        {product.price * product.quantity}
                        </p>
                        <p className="text-gray-500">
                        Quantity:
                        {product.quantity}
                        </p>
                        <p className="text-gray-500">
                        Total Savings: {currencySymbol}
                        {(product.originalPrice - product.price) *
                            product.quantity}
                        </p>
                    </div>
                    <hr className="my-8" />
                    </div>
                    ))}
                    <hr className="my-4" />
                    <p className="font-semibold">
                        Sub Total: {currencySymbol}
                        {subtotal}
                      </p>
                    <p className="font-semibold">
                        Delivery Charge: {currencySymbol}
                        {deliveryCharge}
                      </p>
                      <p className="font-semibold">
                        Packing Charge: {currencySymbol}
                        {serviceCharge}
                      </p><p className="font-semibold">
                        Order Total: {currencySymbol}
                        {orderTotal}
                      </p>
                  </div>
              </div>
            </div>
            <div className="text-center">
              <Button
                type="submit"
                loading={loadingBtn}
                onClick={() => handleFormSubmit()}
                className="px-8 mb-10 py-4 bg-indigo-500 text-white rounded-lg font-semibold"
              >
                Place Order
              </Button>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default Checkout;
