import { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getDatabase, set, push, ref , onValue } from "firebase/database";
import { getFirestore, collection, setDoc, doc,addDoc, getDocs } from "firebase/firestore";
import {
  getStorage,
  ref as storageRef,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";


const AddProductModal = ({ showModal, setShowModal, handleAddProduct }) => {
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [originalPrice, setOriginalPrice] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState([]);
  const [images, setImages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [uploadProgress, setUploadProgress] = useState("Upload Images");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successAdd, setSuccessAdd] = useState(true);
  const [msg, setMsg] = useState("");
  const [rating, setRating] = useState();
  const [stock,setStock] = useState();
  const [buyInfo,setBuyInfo] =useState();
  const [currency,setCurrency] = useState();

  const [downloadUrls, setDownloadUrls] = useState([]);

  const [newDescription, setNewDescription] = useState(""); // Add this line
  const [details, setDetails] = useState([]); // Add this line
  const [newDetailName, setNewDetailName] = useState(""); // Add this line
  const [newDetailItems, setNewDetailItems] = useState(""); // Add this line

  const storage = getStorage();
  const db = getFirestore();


  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesSnapshot = await getDocs(collection(db, "categories"));
        const categoriesArray = categoriesSnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name,
        }));
        setCategories(categoriesArray);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, [db]);


  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  const handleOriginalPriceChange = (event) => {
    setOriginalPrice(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleRating = (event) => {
    setRating(event.target.value);
  };

  const handleStock = (event) => {
    setStock(event.target.value);
  };

  const handleBuyInfo = (event) => {
    setBuyInfo(event.target.value);
  };

  const handleCurrency = (event) => {
    setCurrency(event.target.value);
  };


  const handleDescriptionChange = (index, event) => {
    const updatedDescription = [...description];
    updatedDescription[index] = event.target.value;
    setDescription(updatedDescription);
  };

  const handleRemoveDescription = (index) => {
    const updatedDescription = [...description];
    updatedDescription.splice(index, 1);
    setDescription(updatedDescription);
  };

  const handleAddDescription = () => {
    if (newDescription !== "") {
      setDescription([...description, newDescription]);
      setNewDescription("");
    }
  };

  const handleDetailsChange = (index, field, event) => {
    const updatedDetails = [...details];
    updatedDetails[index][field] = event.target.value;
    setDetails(updatedDetails);
  };

  const handleRemoveDetail = (index) => {
    const updatedDetails = [...details];
    updatedDetails.splice(index, 1);
    setDetails(updatedDetails);
  };

  const handleAddDetail = () => {
    if (newDetailName !== "" && newDetailItems !== "") {
      setDetails([
        ...details,
        { name: newDetailName, items: newDetailItems },
      ]);
      setNewDetailName("");
      setNewDetailItems("");
    }
  };

 
  const handleSubmit = async (event) => {
    event.preventDefault();
    const product = {
      name,
      price,
      originalPrice,
      category,
      description,
      rating, // Add any other properties here if required
      downloadUrls,
    };
    if (name === '' || category === '' || price === '' || description === '') {
      setMsg('Please complete all fields');
    } else {
      try {
        await handleAddProductdata(product);
        setName('');
        setPrice('');
        setOriginalPrice('');
        setCategory('');
        setDescription([]);
        setDownloadUrls([]);
        setImages([]);
        setSuccessAdd(true);
        setMsg('Product added successfully');
      } catch (error) {
        console.error('Error adding product:', error);
        setError('Error adding product');
      }
    }
  };

  const handleAddProductdata = async (product) => {
    // Replace spaces in the product name with -
    const formattedName = product.name.replace(/\s+/g, '-');
    // Write data to Firestore with the formatted name as the document ID
    await setDoc(doc(db, 'products', formattedName), { ...product });
  };

  
  const handleImageChange = (event) => {
    const selectedImages = Array.from(event.target.files).slice(0, 4);
    setImages((prevImages) => [...prevImages, ...selectedImages]);
  };

  const handleImageCancel = (index) => {
    setImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.splice(index, 1);
      return updatedImages;
    });
  };

  
  const handleImageRemove = (index) => {
    console.log(downloadUrls.length)
    if(downloadUrls.length!=1){
      const updatedUrls = [...downloadUrls];
    updatedUrls.splice(index, 1);
    setDownloadUrls(updatedUrls);
    }
    else{
      setMsg("You can't remove all images")

    }
  };

  const handleUpload = async (event) => {
    event.preventDefault();


    try {
      
      const storageRe = storageRef(storage, "images");
  
      const uploadTasks = images.map((image) => {
        const imageRef = storageRef(storageRe, image.name);
        const uploadTask = uploadBytesResumable(imageRef, image);
  
        uploadTask.on("state_changed", (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setUploadProgress("Uploading Please Wait");
        });
  
        return uploadTask;
      });
  
      await Promise.all(uploadTasks);

      const downloadUrls = await Promise.all(
        images.map((image) => {
          const imageRef = storageRef(storageRe, image.name);
          return getDownloadURL(imageRef);
        })
      );
      setDownloadUrls(downloadUrls);
      
      setUploadProgress("Upload Images");
      setImages([]);
      setSuccess(true);
      console.log("Download URLs:", downloadUrls);

    } catch (error) {
      setError(error.message);
    }

  }


  return (
    <div
      className={`fixed top-0 left-0 h-full w-full flex items-center justify-center  max-h-screen overflow-y-auto ${
        showModal ? "" : "hidden"
      }`}
    >
      <div className="bg-gray-100 max-h-screen overflow-y-auto w-1/2 shadow-lg rounded-lg pt-8">
        <div className="p-4">
          <h2 className="text-2xl font-bold mb-2">Add Product</h2>
          <form onSubmit={handleSubmit}>
          <div className="mb-4">
          <label htmlFor="name" className="block text-gray-700 font-bold mb-2">
            Name
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={handleNameChange}
            className="border rounded-md px-4 py-2 w-full"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="price" className="block text-gray-700 font-bold mb-2">
            Price
          </label>
          <input
            type="number"
            id="price"
            value={price}
            onChange={handlePriceChange}
            className="border rounded-md px-4 py-2 w-full"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="originalPrice"
            className="block text-gray-700 font-bold mb-2"
          >
            Original Price
          </label>
          <input
            type="number"
            id="originalPrice"
            value={originalPrice}
            onChange={handleOriginalPriceChange}
            className="border rounded-md px-4 py-2 w-full"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="rating"
            className="block text-gray-700 font-bold mb-2"
          >
            Rating
          </label>
          <input
            type="number"
            id="rating"
            value={rating}
            onChange={handleRating}
            className="border rounded-md px-4 py-2 w-full"
          />
        </div>
       
        
        
        <div className="mb-4">
          <label
            htmlFor="category"
            className="block text-gray-700 font-bold mb-2"
          >
            Category
          </label>
          <select
            id="category"
            value={category}
            onChange={handleCategoryChange}
            className="border rounded-md px-4 py-2 w-full"
          >
            <option value="">Select a category</option>
            {categories.map((category) => (
              <option key={category.id} value={category.name}>
                {category.name}
              </option>
            ))}
          </select>
        </div>            
        <div className="mb-4">
              <label
                htmlFor="description"
                className="block text-gray-700 font-bold mb-2"
              >
                Description
              </label>
              <ul>
                {description.map((desc, index) => (
                  <li key={index} className="flex items-center">
                    <input
                      type="text"
                      value={desc}
                      onChange={(event) => handleDescriptionChange(index, event)}
                      className="border rounded-md px-4 py-2 w-full"
                    />
                    <button type="button" onClick={() => handleRemoveDescription(index)}>
                      ❌
                    </button>
                  </li>
                ))}
                <li>
                  <input
                    type="text"
                    value={newDescription}
                    onChange={(event) => setNewDescription(event.target.value)}
                    className="border rounded-md px-4 py-2 w-full"
                  />
                  <button type="button" onClick={handleAddDescription}>
                    Add Description
                  </button>
                </li>
              </ul>
            </div>
            
    <div className="mb-4">
          <label
            htmlFor="images"
            className="block text-gray-700 font-bold mb-2"
          >
            Images
          </label>
          <input
            type="file"
            id="images"
            accept="image/*"
            onChange={handleImageChange}
            multiple
            className="hidden"
          />
          <div className="flex flex-wrap -mx-4">
            {images.map((image, index) => (
              <div key={index} className="relative w-24 h-24 mx-4 my-2">
                <button
                  type="button"
                  className="absolute top-0 right-0 text-gray-700 hover:text-red-500"
                  onClick={() => handleImageCancel(index)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M6.293 6.293a1 1 0 011.414 0L10 8.586l2.293-2.293a1 1 0 011.414 1.414L11.414 10l2.293 2.293a1 1 0 01-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 01-1.414-1.414L8.586 10 6.293 7.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                <img
                  src={URL.createObjectURL(image)}
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            ))}
            {images.length < 4 && (
              <div className="relative m-2">
                <label
                  htmlFor="image"
                  className="h-24 w-24 rounded-md border-dashed border-2 border-gray-400 flex items-center justify-center cursor-pointer"
                >
                  <input
                    type="file"
                    id="image"
                    onChange={handleImageChange}
                    className="sr-only"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10 text-gray-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M15 8a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1V9a1 1 0 011-1h1V6a2 2 0 012-2h4a2 2 0 012 2v1h1zm-7-2a1 1 0 00-1 1v1h2V7a1 1 0 00-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                </label>
              </div>
            )}
             {/* {uploadProgress > 0 && (
              <div className="mt-2">
                <progress className="w-full h-2" value={uploadProgress} max="100" />
              </div>
            )} */}
            {images.length > 0 && (
              <button
                onClick={handleUpload}
                className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                {uploadProgress}
              </button>
            )}
            {success && <div className="text-green-500">{downloadUrls.length} Images uploaded successfully!</div>}
            {error && <div className="text-red-500">Error: {error}</div>}
          </div>
        </div>
        {successAdd && <div className="text-green-500">{msg}</div>}

        <div className="flex justify-end">
          <button
            type="button"
            className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 mr-2"
            onClick={() => setShowModal(false)}
          >
            Cancel / Close
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
          >
            Save
          </button>

        </div>
          </form>
        </div>
      </div>
    </div>
  );


}

           

export default AddProductModal;
