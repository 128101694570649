import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useCart } from "../cookies/CartProvider";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import OrderSuccessMessage from "../components/Checkout/OrderSucess";




const OrderComplete = () => {
  const { orderId } = useParams();
  const { cartItems, addToCart, removeFromCart, clearCart } = useCart();



  useEffect(() =>{
    clearCart();
  },[]);

  clearCart();


  return (
    <>
      <Header />
      <OrderSuccessMessage orderId={orderId} />
      <Footer />
    </>
  );
};

export default OrderComplete;
