import React, { useState, useEffect } from 'react';
import { doc, getFirestore, getDoc, updateDoc } from 'firebase/firestore';

const EditZipCodeModal = ({ zipCodeToEdit, showEditZipCode, setShowEditZipCode }) => {
  const [zipCode, setZipCode] = useState('');
  const [transportation, setTransportation] = useState('');
  const [service, setService] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Load existing data for editing when the modal is opened
    if (showEditZipCode) {
      const fetchData = async () => {
        try {
          const firestore = getFirestore();
          const zipCodeDocRef = doc(firestore, 'zipcode', zipCodeToEdit);

          const docSnapshot = await getDoc(zipCodeDocRef);
          if (docSnapshot.exists()) {
            const zipCodeData = docSnapshot.data();
            setZipCode(zipCodeToEdit);
            setTransportation(zipCodeData.transportation.toString()); // Convert to string for input field
            setService(zipCodeData.service.toString()); // Convert to string for input field
          } else {
            setError('Zip Code not found.');
          }
        } catch (error) {
          console.error('Error fetching zip code data:', error);
          setError('Error fetching zip code data.');
        }
      };

      fetchData();
    }
  }, [showEditZipCode, zipCodeToEdit]);

  const handleEditZipCode = async () => {
    try {
      const firestore = getFirestore();
      const zipCodeDocRef = doc(firestore, 'zipcode', zipCode);

      // Create the data object with transportation and service values
      const updatedZipCodeData = {
        transportation: parseInt(transportation), // Convert to number
        service: parseInt(service), // Convert to number
      };

      // Update the data in Firestore
      await updateDoc(zipCodeDocRef, updatedZipCodeData);

      // Clear form fields and show success message
      setSuccess(true);
      setError(null);
    } catch (error) {
      console.error('Error editing zip code:', error);
      setError('Error editing zip code');
      setSuccess(false);
    }
  };

  return (
    <>
      {/* Render a button to open the edit modal */}
      {/* Similar to your Add Zip Code button, but with an onClick event to show the modal */}

      {showEditZipCode && (
        <div className="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center">
          <div className="bg-white w-96">
          <div className="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center">
          <div className="bg-white w-96">
            <div className="flex justify-between px-4 py-3 bg-gray-300 text-black font-bold border">
              <div className="px-4 py-3 text-xl">Add Zip Code</div>
              <button
                onClick={() => setShowEditZipCode(false)}
                className="px-4 py-3 rounded bg-red-500 hover:bg-red-700"
              >
                Close
              </button>
            </div>

            <div className="p-8">
              <div className="mb-4">
                <label htmlFor="zipCode" className="block font-bold text-gray-700">
                  Zip Code
                </label>
                <input
                  type="text"
                  id="zipCode"
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                  className="w-full border border-black rounded"
                />
              </div>

              <div className="mb-4">
                <label htmlFor="transportation" className="block font-bold text-gray-700">
                  Transportation
                </label>
                <input
                  type="number"
                  id="transportation"
                  value={transportation}
                  onChange={(e) => setTransportation(e.target.value)}
                  className="w-full border border-black rounded"
                />
              </div>

              <div className="mb-4">
                <label htmlFor="service" className="block font-bold text-gray-700">
                  Packing
                </label>
                <input
                  type="number"
                  id="service"
                  value={service}
                  onChange={(e) => setService(e.target.value)}
                  className="w-full border border-black rounded"
                />
              </div>

              <button
                onClick={handleEditZipCode}
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                Add Zip Code
              </button>

              {success && (
                <div className="text-green-500 mt-2">Zip Code added successfully!</div>
              )}

              {error && <div className="text-red-500">Error: {error}</div>}
            </div>
          </div>
        </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditZipCodeModal;
