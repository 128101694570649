import React, { useEffect, useState } from "react";
import { GoogleLoginButton } from "./GoogleBtn";
import { getAuth } from "firebase/auth";
import firebaseConfig from "../../../firebase/firebaseConfig";
import { signOut } from "firebase/auth";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
// import MobileLoginOverlay from "./MobileLoginOverlay";
import { Link, useNavigate } from "react-router-dom";
import { useCart } from "../../cookies/CartProvider";
import { set } from "firebase/database";
import MobileLoginOverlay from "./MobileLoginOverlay";

const auth = getAuth();

const Header = ({ back }) => {
  const navigate = useNavigate();
  const [isback, setBack] = useState(back);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenMobile, setIsOpenMobile] = useState(false);
  const [isOpenMobileEvents, setIsOpenMobileEvents] = useState(false);
  const [isLogedIn, setLoggedIn] = useState(false);
  const [isLogedInOptions, setLogedInOptions] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isCartOpen, setCartOpen] = useState(false);
  const [isLoginOpen, setLoginOpen] = useState(false);
  const { cartItems, addToCart, removeFromCart, clearCart } = useCart();
  const [cartItemsNo, setCartItemsNo] = useState(0);

  useEffect(() => {
    var ttCart = 0;
    cartItems.forEach((element) => {
      ttCart += element.quantity;
    });
    setCartItemsNo(ttCart);
  }, [cartItems]);

  useEffect(() => {
    // Check if the user is already logged in
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
    });

    // Cleanup the auth state change listener
    return () => unsubscribe();
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdownmobile = () => {
    setLogedInOptions(false)

    setIsOpenMobile(!isOpenMobile);

  };

  const toggleDropdownmobileEvents = () => {
    setIsOpenMobileEvents(!isOpenMobileEvents);
  };

  const handelLogin = () => {
    if (isLogedIn) {
      
     setIsOpenMobile(false)
      setDropdownVisible(false);
      setLogedInOptions(!isLogedInOptions);
    } else {
      setLoginOpen(true);
    }
  };

  const handleDropdownToggle = () => {
        setDropdownVisible(!isDropdownVisible);

      
  };

  const handleGoBack = (event) => {
    event.preventDefault();
    navigate(-1); // Navigates back to the previous route
  };

  const handleGoogleLogin = () => {
    console.log("Hello");
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    // Sign in with Google popup
    signInWithPopup(auth, provider)
      .then((result) => {
        setLoginOpen(false);
        // Redirect to admin home after successful login
      })
      .catch((error) => {
        console.error("Login failed:", error);
      });
  };

  const handleLogout = () => {
    // Call the Firebase sign out function
    signOut(auth)
      .then(() => {
        setLogedInOptions(false);
        // Logout successful, perform any additional actions
        console.log("User logged out successfully.");
        window.location.href = "/";
      })
      .catch((error) => {
        // Handle error if logout fails
        console.error("Logout error:", error);
      });
  };

  return (
    <>
      {isLoginOpen && (
        <MobileLoginOverlay setLoginOpen={setLoginOpen}></MobileLoginOverlay>
      )}

      <nav class="sticky shadow top-0 z-50 border-gray-600 bg-white">
        <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl p-4">
          <div class="flex items-center md:w-auto w-full">
            <div class="flex items-center">
              {isback ? (
                <button
                  onClick={handleGoBack}
                  className="focus:outline-none cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-black"
                    viewBox="0 0 21 22"
                  >
                    <path
                      fill="currentColor"
                      d="M.27 9.992a1.289 1.289 0 0 0-.266.42L0 10.424l.004-.01c.061-.156.151-.3.267-.421Zm10.82-7.578-7.534 7.51h16.032a.989.989 0 0 1 0 1.977H3.555l7.536 7.512a.989.989 0 0 1 0 1.404l-.004.004h-.001a.988.988 0 0 1-1.404 0L.486 11.625a.988.988 0 0 1-.202-.32l-.007-.016a.988.988 0 0 1 0-.75l.007-.017a.989.989 0 0 1 .202-.32L9.68 1.006a.996.996 0 1 1 1.408 1.408Z"
                    ></path>
                  </svg>
                </button>
              ) : (
                <button
                  onClick={toggleDropdownmobile}
                  data-collapse-toggle="mobile-menu-2"
                  type="button"
                  className="relative group inline-flex items-center p-2 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 text-gray-400 hover:bg-gray-200 focus:ring-gray-200 ml-auto lg:ml-0 lg:hidden"                  aria-controls="mobile-menu-2"
                  aria-expanded="false"
                >
                  <span class="sr-only">Open main menu</span>
                  <svg
                    class="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <svg
                    class="hidden w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              )}
            </div>

            <Link to="/" class="flex items-center ml-5 ">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/sproutive-1763d.appspot.com/o/sproutive-logo.png?alt=media&token=45162347-bace-40c6-b1d2-632fbbd62469"
                class="h-12 mr-3"
              />
            </Link>

            <div class="flex items-center ml-auto">
              <div class="flex items-center ml-auto  lg:order-2">
                <button
                  onClick={handelLogin}
                  data-collapse-toggle="mobile-menu-2"
                  type="button"
                  className="relative group inline-flex items-center p-2 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 text-gray-400 hover:bg-gray-200 focus:ring-gray-200 ml-auto lg:ml-0 lg:hidden"                  aria-controls="mobile-menu-2"
                  aria-expanded="false"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-6 h-6"
                    viewBox="0 0 24 24"
                    width="1em"
                    height="1em"
                  >
                    <path
                      d="M15.7 12.71A6 6 0 0 0 12 2a6 6 0 0 0-5.77 7.68 5.93 5.93 0 0 0 2 3 10 10 0 0 0-6.22 8.18.86.86 0 0 0 0 .39 1.06 1.06 0 0 0 .18.35A1 1 0 0 0 3 22a1 1 0 0 0 .73-.22 1 1 0 0 0 .38-.67 8 8 0 0 1 15.9 0 1 1 0 0 0 .32.64A1.06 1.06 0 0 0 21 22h.11a1 1 0 0 0 .66-.37 1 1 0 0 0 .23-.73 10 10 0 0 0-6.25-8.19ZM12 12a4 4 0 0 1-2.23-.67 4 4 0 0 1-.6-6.16 4 4 0 0 1 2-1.09A4 4 0 1 1 12 12Z"
                      fill="#000000"
                      stroke="#000000"
                      stroke-width="0.1"
                    ></path>
                  </svg>
                </button>
              </div>

              <div class="flex items-center ml-auto  lg:order-2">
                <Link
                  to="/cart"
                  data-collapse-toggle="mobile-menu-2"
                  type="button"
                  className="relative group inline-flex items-center p-2 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 text-gray-400 hover:bg-gray-200 focus:ring-gray-200 ml-auto lg:ml-0 lg:hidden"                  aria-controls="mobile-menu-2"
                  aria-expanded="false"
                >
                  <div class="relative">
                    <svg
                      width="1em"
                      class="w-6 h-6"
                      height="1em"
                      viewBox="0 0 21 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.22 6.049v.05h3.626c.304 0 .595.112.808.309.214.197.333.464.333.741v12.1c0 .86-.37 1.687-1.03 2.297-.662.61-1.559.953-2.495.953H4.546a3.682 3.682 0 0 1-2.494-.953c-.661-.61-1.031-1.436-1.031-2.297v-12.1c0-.277.119-.544.333-.741.213-.197.504-.309.808-.309h3.625V4.949c0-1.152.496-2.258 1.38-3.075C8.052 1.058 9.252.6 10.504.6c1.252 0 2.452.46 3.337 1.275.884.817 1.38 1.923 1.38 3.075v1.1Zm-2.333.05h.05V4.949c0-.598-.257-1.17-.714-1.592a2.538 2.538 0 0 0-1.719-.658c-.644 0-1.263.236-1.72.658a2.168 2.168 0 0 0-.713 1.592V6.099h4.816Zm-9.533 2.1h-.05v11.05c0 .306.132.6.365.815.233.215.549.335.877.335h11.916c.328 0 .644-.12.877-.335.233-.216.365-.509.365-.815V8.199H15.221V9.349c0 .277-.12.544-.333.741a1.194 1.194 0 0 1-.809.309c-.304 0-.595-.111-.809-.309a1.009 1.009 0 0 1-.333-.741V8.199H8.071V9.349c0 .277-.12.544-.333.741a1.194 1.194 0 0 1-.809.309c-.304 0-.595-.111-.809-.309a1.009 1.009 0 0 1-.333-.741V8.199H3.354Z"
                        fill="#000000"
                        stroke="#000000"
                        stroke-width="0.1"
                      ></path>
                    </svg>
                    {cartItemsNo > 0 && (
                      <span
                        class="absolute text-center bg-green-400 text-black text-xs font-medium leading-none rounded-full w-4 h-4 flex justify-center items-center top-0 right-0 -mt-1 -mr-1"
                      >
                        {cartItemsNo}
                      </span>
                    )}
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div class=" z-50">
            {isOpenMobile && (
              <div className="absolute left-0  right-0 z-50 mt-9  shadow-sm border-y bg-gray-200 border-gray-200">
                <ul class="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                  <li>
                    <Link
                      to="/"
                      class="block py-2 pr-4 pl-3 bg-gray-200 text-black  border-b border-gray-100 hover:bg-black lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 text-black lg:hover:text-black hover:bg-gray-700 hover:text-black lg:hover:bg-transparent border-gray-700"
                      aria-current="page"
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/Products"
                      class="block py-2 pr-4 pl-3  border-b border-gray-100 hover:bg-black lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 text-black lg:hover:text-black hover:bg-gray-700 hover:text-black lg:hover:bg-transparent border-gray-700"
                    >
                      Products
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/About"
                      class="block py-2 pr-4 pl-3  border-b border-gray-100 hover:bg-black lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 text-black lg:hover:text-black hover:bg-gray-700 hover:text-black lg:hover:bg-transparent border-gray-700"
                    >
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/Contact"
                      class="block py-2 pr-4 pl-3  border-b border-gray-100 hover:bg-black lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 text-black lg:hover:text-black hover:bg-gray-700 hover:text-black lg:hover:bg-transparent border-gray-700"
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>

          <div class="z-50">
            {isLogedInOptions && (
              <div className="absolute left-0  right-0 z-50 mt-9  shadow-sm border-y bg-gray-200 border-gray-200">
              <ul class="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                  <li>
                    <Link
                      to="/myOrders"
                      class="block py-2 pr-4 pl-3  border-b border-gray-100 hover:bg-black lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 text-black lg:hover:text-black hover:bg-gray-700 hover:text-black lg:hover:bg-transparent border-gray-700"
                      aria-current="page"
                    >
                      My Orders
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/Contact"
                      class="block py-2 pr-4 pl-3  border-b border-gray-100 hover:bg-black lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 text-black lg:hover:text-black hover:bg-gray-700 hover:text-black lg:hover:bg-transparent border-gray-700"
                    >
                      Support
                    </Link>
                  </li>
                  <li>
                    <button
                      onClick={handleLogout}
                      className="block w-full text-left py-2 pr-4 pl-3 border-b border-gray-100 hover:bg-black lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 text-black lg:hover:text-black hover:bg-gray-700 hover:text-black lg:hover:bg-transparent border-gray-700"                    >
                      {" "}
                      Logout
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>

          <div
            id="mega-menu-full"
            class="items-center hidden w-full md:flex md:w-auto md:order-1 lg:items-center justify-center mx-auto"
          >
            <ul class="flex flex-col mt-4 font-medium md:flex-row md:space-x-8 md:mt-0">
              <li>
                <Link
                  to="/"
                  class="block py-2 pl-3 pr-4 text-gray-900 border-b border-gray-100 hover:bg-black md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 text-black md:hover:text-blue-500 hover:bg-gray-700 hover:text-blue-500 md:hover:bg-transparent border-gray-700"
                  aria-current="page"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/Products"
                  class="block py-2 pl-3 pr-4 text-gray-900 border-b border-gray-100 hover:bg-black md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 text-black md:hover:text-blue-500 hover:bg-gray-700 hover:text-blue-500 md:hover:bg-transparent border-gray-700"
                >
                  Products
                </Link>
              </li>
              <li>
                <Link
                  to="/Contact"
                  class="block py-2 pl-3 pr-4 text-gray-900 border-b border-gray-100 hover:bg-black md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 text-black md:hover:text-blue-500 hover:bg-gray-700 hover:text-blue-500 md:hover:bg-transparent border-gray-700"
                >
                  Contact Us
                </Link>
              </li>
              <li>
                <Link
                  to="/About"
                  class="block py-2 pl-3 pr-4 text-gray-900 border-b border-gray-100 hover:bg-black md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 text-black md:hover:text-blue-500 hover:bg-gray-700 hover:text-blue-500 md:hover:bg-transparent border-gray-700"
                >
                  About Us
                </Link>
              </li>
            </ul>
          </div>

          <div
            id="mega-menu-full"
            className="items-center hidden w-full md:flex md:w-auto md:order-1 lg:items-center justify-center"
          >
            {/* Account */}
            <div
              onClick={handleDropdownToggle}
              onTouchMove={handleDropdownToggle}
              className="relative group mr-2 inline-flex items-center p-2 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 text-gray-400 hover:bg-gray-200 focus:ring-gray-200 ml-auto lg:ml-0"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                viewBox="0 0 24 24"
                width="1em"
                height="1em"
              >
                <path
                  d="M15.7 12.71A6 6 0 0 0 12 2a6 6 0 0 0-5.77 7.68 5.93 5.93 0 0 0 2 3 10 10 0 0 0-6.22 8.18.86.86 0 0 0 0 .39 1.06 1.06 0 0 0 .18.35A1 1 0 0 0 3 22a1 1 0 0 0 .73-.22 1 1 0 0 0 .38-.67 8 8 0 0 1 15.9 0 1 1 0 0 0 .32.64A1.06 1.06 0 0 0 21 22h.11a1 1 0 0 0 .66-.37 1 1 0 0 0 .23-.73 10 10 0 0 0-6.25-8.19ZM12 12a4 4 0 0 1-2.23-.67 4 4 0 0 1-.6-6.16 4 4 0 0 1 2-1.09A4 4 0 1 1 12 12Z"
                  fill="#000000"
                  stroke="#000000"
                  strokeWidth="0.1"
                ></path>
              </svg>

              <div
                className={`absolute bg-gray-800 py-2 mt-2 rounded shadow-md w-48 z-50 right-0 transform translate-x-1/2 ${
                  isDropdownVisible ? "block" : "hidden"
                }`}
                style={{
                  top: "calc(100% + 0.5rem)",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                {/* Dropdown content */}
                {!isLogedIn ? (
                  <GoogleLoginButton
                    onClick={handleGoogleLogin}
                  ></GoogleLoginButton>
                ) : (
                  <>
                    <Link
                      to="/myorders"
                      className="block text-gray-200 px-4 py-2 text-sm hover:bg-gray-700"
                    >
                      My Orders
                    </Link>
                    <Link
                      to="/contact"
                      className="block text-gray-200 px-4 py-2 text-sm hover:bg-gray-700"
                    >
                      Support
                    </Link>
                    <button
                      onClick={handleLogout}
                      className="block w-full bg-gray-800 text-gray-200 text-left px-4 py-2 text-sm hover:bg-gray-700"
                    >
                      Logout
                    </button>
                  </>
                )}
              </div>
            </div>
            
            <div className="relative group inline-flex items-center p-2 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 text-gray-400 hover:bg-gray-200 focus:ring-gray-200 ml-auto lg:ml-0">
              <Link to="/cart">
                <svg
                  width="1em"
                  className="w-6 h-6"
                  height="1em"
                  viewBox="0 0 21 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.22 6.049v.05h3.626c.304 0 .595.112.808.309.214.197.333.464.333.741v12.1c0 .86-.37 1.687-1.03 2.297-.662.61-1.559.953-2.495.953H4.546a3.682 3.682 0 0 1-2.494-.953c-.661-.61-1.031-1.436-1.031-2.297v-12.1c0-.277.119-.544.333-.741.213-.197.504-.309.808-.309h3.625V4.949c0-1.152.496-2.258 1.38-3.075C8.052 1.058 9.252.6 10.504.6c1.252 0 2.452.46 3.337 1.275.884.817 1.38 1.923 1.38 3.075v1.1Zm-2.333.05h.05V4.949c0-.598-.257-1.17-.714-1.592a2.538 2.538 0 0 0-1.719-.658c-.644 0-1.263.236-1.72.658a2.168 2.168 0 0 0-.713 1.592V6.099h4.816Zm-9.533 2.1h-.05v11.05c0 .306.132.6.365.815.233.215.549.335.877.335h11.916c.328 0 .644-.12.877-.335.233-.216.365-.509.365-.815V8.199H15.221V9.349c0 .277-.12.544-.333.741a1.194 1.194 0 0 1-.809.309c-.304 0-.595-.111-.809-.309a1.009 1.009 0 0 1-.333-.741V8.199H8.071V9.349c0 .277-.12.544-.333.741a1.194 1.194 0 0 1-.809.309c-.304 0-.595-.111-.809-.309a1.009 1.009 0 0 1-.333-.741V8.199H3.354Z"
                    fill="#000000"
                    stroke="#000000"
                    strokeWidth="0.1"
                  ></path>
                </svg>
              </Link>
              {cartItemsNo > 0 && (
                <span className="absolute top-0 right-0 -mb-2 -ml-2 flex items-center justify-center h-4 w-4 bg-green-400 text-black text-xs font-medium leading-none rounded-full">
                  {cartItemsNo}
                </span>
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
