import React, { useState } from "react";
import { getFirestore,doc, updateDoc } from "firebase/firestore";


const EditCategoryModal = ({ showModal, setShowModal, category, categoryId }) => {
  const [categoryName, setCategoryName] = useState(category.name);
  const [imageUrl, setImageUrl] = useState(category.imageUrl);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const db = getFirestore();


  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageUrl(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleCancel = () => {
    setShowModal(false);
    setCategoryName(category.name);
    setImageUrl(category.imageUrl);
    setError(null);
    setSuccess(false);
  };

 
const handleEditCategory = async (e) => {
    e.preventDefault();
    try {
      // Get a reference to the category document in Firestore
      const categoryDocRef = doc(db, "categories", categoryId);
  
      // Update the fields with the new data
      await updateDoc(categoryDocRef, {
        name: categoryName,
        imageUrl: imageUrl,
      });
  
      // Set success to true and close the modal after 3 seconds
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        setShowModal(false);
      }, 3000);
      setError(null);
    } catch (error) {
      setError("Error updating category: " + error.message);
      setTimeout(() => {
        setError(null);
      }, 3000);
    }
  };

  return (
    <div className={`fixed top-0 left-0 h-full w-full flex items-center justify-center ${showModal ? "" : "hidden"}`}>
      <div className="bg-gray-100 w-1/2 shadow-lg rounded-lg">
        <div className="p-4">
          <h2 className="text-2xl font-bold mb-2">Edit Category</h2>
          <form onSubmit={handleEditCategory}>
            <div className="mb-4">
              <label htmlFor="categoryName" className="block text-gray-700 font-bold mb-2">Category Name</label>
              <input
                type="text"
                id="categoryName"
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
                className="border rounded-md px-4 py-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="image" className="block text-gray-700 font-bold mb-2">Image</label>
              <img src={imageUrl} alt="Category" className="h-24 w-24 mb-2 rounded-md" />
              <input
                type="file"
                id="image"
                onChange={handleImageChange}
                className="border rounded-md px-4 py-2 w-full"
              />
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 mr-2"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
        {success && (
          <div className="bg-green-200 text-green-700 p-2 rounded-md my-2">
            Category updated successfully!
          </div>
        )}
        {error && (
          <div className="bg-red-200 text-red-700 p-2 rounded-md my-2">
            {error}
          </div>
        )}
      </div>
    </div>
  );
};

export default EditCategoryModal;
