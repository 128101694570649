import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom"
import App from "./webApp/pages/App"
import Contact from './webApp/pages/ContactUs';
import About from './webApp/pages/AboutUs';
import Products from './webApp/pages/Products';
import MobileCartView from './webApp/pages/CartPage';
import AdminHome from './admin/Home';
import AdminLogin from './admin/AdminLogin';
import AdminProducts from './admin/Products';
import AdminCategories from './admin/Categories';
import Orders from './admin/Orders';
import AdminSliderPage from './admin/Slider';
import ProductPage from './webApp/pages/Product';
import Order from './webApp/pages/PlaceOrder';
import OrderComplete from './webApp/pages/OrdersComplete';
import MyOrderPage from './webApp/pages/MyOrders';
import ZipcodePage from './admin/ZipCode';

 


 const AppRouter = () => {


    return(
        <BrowserRouter>
            <Routes>
            <Route path="/" element={<App />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/About" element={<About />} />
            <Route path="/Products" element={<Products />} />
            <Route path="/cart" element={<MobileCartView />} />
            <Route path="/product/:productId" element={<ProductPage />} />
            <Route path="/order/:cartId" element={<Order />} />
            <Route path="/orderplaced/:orderId" element={<OrderComplete />} />
            <Route path="/myorders" element={<MyOrderPage />} />






            <Route path="/admin" element={<AdminLogin />} />
            <Route path="/admin/home" element={<AdminHome />} />
            <Route path="/admin/products" element={<AdminProducts />} />
            <Route path="/admin/categories" element={<AdminCategories />} />
            <Route path="/admin/orders" element={<Orders />} />
            <Route path="/admin/slider" element={<AdminSliderPage />} />
            <Route path="/admin/zipcode" element={<ZipcodePage />} />









            </Routes>
        </BrowserRouter>

    )
 }

 export default AppRouter