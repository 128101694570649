// DashboardCard.js

import React from "react";

const DashboardCard = ({ title, value }) => {
  const colors = ["bg-blue-500", "bg-green-400", "bg-yellow-300", "bg-purple-500", "bg-red-600"];
  const randomColor = colors[Math.floor(Math.random() * colors.length)];

  console.log(randomColor)

  return (
    <div className={`${randomColor} rounded-lg p-4 shadow-md`}>
      <h3 className="text-xl font-bold mb-2">{title}</h3>
      <p className="text-2xl font-bold">{value}</p>
    </div>
  );
};

export default DashboardCard;
