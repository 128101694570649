import React, { useEffect, useState } from "react";
import SidePanel from "./Components/SidePanel";
import AdminHeader from "./Components/Header";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import AdminSlider from "./Components/SliderAdmin";
import { get, getDatabase, onValue, ref, remove } from "firebase/database";
import { collection, deleteDoc, doc, getDocs, getFirestore } from "firebase/firestore";
import AddZipCodeModal from "./Components/AddZipcode";
import EditZipCodeModal from "./Components/EditZipcode";

const ZipcodePage = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [ZipCodeData, setZipCodeData] = useState([]);
  const [ShowAddZipCode, setShowAddZipCode] = useState(false);
  const [ShowEditZipCode, setShowEditZipCode] = useState(false);
  const [zipKey, setzipKey] = useState('');
  const itemsPerPage = 10; // Set the number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);

  // Function to handle page changes
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const checkAdminStatus = (user) => {
      if (user && user.email === 'sproutive.in@gmail.com' || "manuvini29@gmail.com") {
        setLoggedIn(true);
      } else {
        window.location.href = '/admin';
      }
    };

    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      checkAdminStatus(user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Create a reference to the Firestore database
    const firestoreDB = getFirestore();

    // Reference the 'zipcode' collection
    const zipcodeCollection = collection(firestoreDB, 'zipcode');

    // Fetch the data from Firestore
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(zipcodeCollection);

        if (!querySnapshot.empty) {
          const zipData = querySnapshot.docs.map((doc, index) => ({
            key: doc.id,
            transport: doc.data().transportation,
            service: doc.data().service,
            id: index + 1,
          }));
          setZipCodeData(zipData);
        } else {
          // If no data is available, reset the state or show a message accordingly
          setZipCodeData([]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [ShowAddZipCode]);

  const handleDelete = async (key) => {
    try {
      const firestore = getFirestore();
      const zipCodeDocRef = doc(firestore, 'zipcode', key); // Reference the specific document by key

      await deleteDoc(zipCodeDocRef);

      // Remove the deleted item from the local state
      setZipCodeData((prevData) => prevData.filter((zip) => zip.key !== key));
      console.log("Deleted the Zip Code");
    } catch (error) {
      console.error("Error deleting the Zip Code:", error);
    }
  };

  const handleEdit = (key) => {
    setzipKey(key);
    setShowEditZipCode(true);
  }

  if (!loggedIn) {
    return null; // or return a loading/spinner component
  }

  // Calculate the index range for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Filter the data for the current page
  const currentPageData = ZipCodeData.slice(startIndex, endIndex);

  // Calculate the total number of pages
  const totalPages = Math.ceil(ZipCodeData.length / itemsPerPage);

  return (
    <div className="flex h-screen">
      <SidePanel />
      <div className="flex-1 ml-56">
        <AdminHeader />
        {ShowAddZipCode && <AddZipCodeModal showAddZipCode={ShowAddZipCode} setShowAddZipCode={setShowAddZipCode}></AddZipCodeModal>}
        {ShowEditZipCode && <EditZipCodeModal zipCodeToEdit={zipKey} showEditZipCode={ShowEditZipCode} setShowEditZipCode={setShowEditZipCode} ></EditZipCodeModal>}

        <div className="p-6">
          <div className="flex justify-between ">
            <h2 className="text-2xl font-bold mb-4">Slider</h2>
            <div>
              <button
                onClick={() => setShowAddZipCode(true)}
                className="px-6 py-3 bg-green-500 text-white rounded-md hover:bg-green-600 mr-2"
              >
                Add ZipCode
              </button>
            </div>
          </div>
        </div>
        <>
          <table className="w-full">
            <thead>
              <tr className="bg-gray-300 border-collapse">
                <th className="py-3 px-6 text-gray-700 border">ZipCode</th>
                <th className="py-3 px-6 text-gray-700 border">Transport</th>
                <th className="py-3 px-6 text-gray-700 border">Packing</th>
                <th className="py-3 px-6 text-gray-700 border">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentPageData.map((zip) => {
                return (
                  <tr key={zip.id}>
                    <td className="px-8 py-3 border">{zip.key}</td>
                    <td className="px-8 py-3 border">{zip.transport}</td>
                    <td className="px-8 py-3 border">{zip.service}</td>
                    <td className="px-10 py-3 border w-1/7">
                      <button
                        onClick={() => handleEdit(zip.key)}
                        className="px-6 py-3 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleDelete(zip.key)}
                        className="px-6 py-3 bg-red-500 text-white rounded-md hover:bg-red-600"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {/* Pagination controls */}
          <div className="flex justify-center mt-4">
            <ul className="flex space-x-2">
              {Array.from({ length: totalPages }).map((_, index) => (
                <li key={index}>
                  <button
                    onClick={() => handlePageChange(index + 1)}
                    className={`${
                      currentPage === index + 1
                        ? "bg-blue-500 text-white"
                        : "bg-gray-300 text-gray-700"
                    } px-4 py-2 rounded-md hover:bg-blue-500 hover:text-white`}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </>
      </div>
    </div>
  );
};

export default ZipcodePage;
