
import { useParams } from 'react-router-dom';
import { useEffect, useLayoutEffect } from 'react';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import Checkout from '../components/Checkout/Checkout';



function Order() {
  var { cartId } = useParams();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);



 
  

  return (
    <>
        <Header back={true}></Header>
        <Checkout cartId={cartId}></Checkout>
        <Footer></Footer>
    </>
  );
}

export default Order;
