import React from "react";
import { PhoneIcon, MailIcon, MapIcon } from "@heroicons/react/outline";

function ContactUsContent() {
  return (
    <div>
      <section className="section pt-10 pb-10">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="card border-0 text-center features feature-primary feature-clean">
              <div className="icons text-center mx-auto">
                <PhoneIcon className="mx-auto h-12 w-12 text-green-600" />
              </div>
              <div className="content ml-5 mt-8 mb-6">
                <h5 className="font-bold text-lg mb-2">Phone</h5>
                <p className="text-gray-500">
                  Contact us to reslove ypur any issue.
                </p>
                <a
                  href="tel:+91 9632019696"
                  className="read-more text-green-600"
                >
                  +91 9632019696
                </a>
              </div>
            </div>

            <div className="card border-0 text-center features feature-primary feature-clean">
              <div className="icons text-center mx-auto">
                <MailIcon className="mx-auto my-auto h-12 w-12 text-green-600" />
              </div>
              <div className="content ml-5 mt-8 mb-6">
                <h5 className="font-bold text-lg mb-2">Email</h5>
                <p className="text-gray-500">
                  Ping us on email our sales team will contact you and will help
                  you with the issue.
                </p>
                <a
                  href="mailto:contact@example.com"
                  className="read-more text-green-600"
                >
                  contact@sproutive.in
                </a>
              </div>
            </div>

            <div className="card border-0 text-center features feature-primary feature-clean">
              <div className="icons  text-center mx-auto">
                <MapIcon className="mx-auto h-12 w-12 text-green-600" />
              </div>
              <div className="content ml-5 mt-8 mb-6">
                <h5 className="font-bold text-lg mb-2">Location</h5>
                <p className="text-gray-500">
                Below Style Union Mall, Kasavanahalli, Bangalore, India- 560035
                </p>
                {/* <a href="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3889.1563100076637!2d77.677197!3d12.8976687!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae13c59b7ce8e3%3A0x747497691d804b53!2sFootsypop%20Events!5e0!3m2!1sen!2sin!4v1671213299397!5m2!1sen!2sin" */}
                {/* data-type="iframe" className="video-play-icon read-more lightbox text-green-600">View on Google map</a> */}
              </div>
            </div>

          </div>
        </div>
        <div class="container-fluid mt-10 mb-0  ">
          <div class="row h-500">
            <div class="col-12 p-0 h-500">
              <div class="card map border-0 h-500">
                <div class="card-body p-0 h-500">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15556.521074189586!2d77.6747291!3d12.899344!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae13391178ed25%3A0xa85ba3af5ee24fbb!2sSproutive%20Swasthyachintak%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1692235968698!5m2!1sen!2sin"
                    class="w-full h-screen"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ContactUsContent;
