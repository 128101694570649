import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Header from '../components/common/Header'
import Footer from '../components/common/Footer'
import ProductListing from '../components/App/SectionProducts'
import AllProductList from '../components/Product/AllProductList'

 function Products() {
  // const count = useSelector((state) => state.counter)
  // const dispatch = useDispatch()

  return (
    <div>
      <Header ></Header>
      <AllProductList></AllProductList>

      <Footer></Footer>
    </div>
  )
}


export default Products