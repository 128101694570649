import React, { useState } from 'react';
import { getDatabase, ref, push } from 'firebase/database';
import { doc, getFirestore, setDoc } from 'firebase/firestore';

const AddZipCodeModal = ({ showAddZipCode, setShowAddZipCode }) => {
  const [zipCode, setZipCode] = useState('');
  const [transportation, setTransportation] = useState('');
  const [service, setService] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  
const handleAddZipCode = async () => {
    try {
      const firestore = getFirestore();
      const zipCodeRef = doc(firestore, 'zipcode', zipCode); // Use zipCode as the document key
  
      // Create the data object with transportation and service values
      const newZipCodeData = {
        transportation: parseInt(transportation), // Convert to number
        service: parseInt(service), // Convert to number
      };
  
      // Set the data in Firestore
      await setDoc(zipCodeRef, newZipCodeData);
  
      // Clear form fields and show success message
      setZipCode('');
      setTransportation('');
      setService('');
      setSuccess(true);
      setError(null);
    } catch (error) {
      console.error('Error adding zip code:', error);
      setError('Error adding zip code');
      setSuccess(false);
    }
  };
  return (
    <>
      <button
        onClick={() => setShowAddZipCode(true)}
        className="bg-blue-500 text-white px-4 py-2 rounded-md"
      >
        Add Zip Code
      </button>

      {showAddZipCode && (
        <div className="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center">
          <div className="bg-white w-96">
            <div className="flex justify-between px-4 py-3 bg-gray-300 text-black font-bold border">
              <div className="px-4 py-3 text-xl">Add Zip Code</div>
              <button
                onClick={() => setShowAddZipCode(false)}
                className="px-4 py-3 rounded bg-red-500 hover:bg-red-700"
              >
                Close
              </button>
            </div>

            <div className="p-8">
              <div className="mb-4">
                <label htmlFor="zipCode" className="block font-bold text-gray-700">
                  Zip Code
                </label>
                <input
                  type="text"
                  id="zipCode"
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                  className="w-full border border-black rounded"
                />
              </div>

              <div className="mb-4 ">
                <label htmlFor="transportation" className="block font-bold text-gray-700">
                  Transportation
                </label>
                <input
                  type="number"
                  id="transportation"
                  value={transportation}
                  onChange={(e) => setTransportation(e.target.value)}
                  className="w-full border border-black rounded"
                />
              </div>

              <div className="mb-4">
                <label htmlFor="service" className="block font-bold text-gray-700">
                  Packing
                </label>
                <input
                  type="number"
                  id="service"
                  value={service}
                  onChange={(e) => setService(e.target.value)}
                  className="w-full border border-black rounded"
                />
              </div>

              <button
                onClick={handleAddZipCode}
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                Add Zip Code
              </button>

              {success && (
                <div className="text-green-500 mt-2">Zip Code added successfully!</div>
              )}

              {error && <div className="text-red-500">Error: {error}</div>}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddZipCodeModal;
