import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import ProductView from "../components/Product/ProductView";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { useCart } from "../cookies/CartProvider";

function ProductPage() {
  // const count = useSelector((state) => state.counter)
  // const dispatch = useDispatch()
  var { productId } = useParams();
  const [product, setProduct] = useState(null);
  const { cartItems, addToCart, removeFromCart, clearCart, onIncrement, onDecrement } = useCart();



  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });  }, []
    );
    useEffect(() => {
      const firestore = getFirestore();
      console.log(productId);
    
      const fetchProductData = async () => {
        try {
          const productDocRef = doc(firestore, "products", productId);
          const unsubscribe = onSnapshot(productDocRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
              const productData = docSnapshot.data();
              console.log(productData);
    
              // Define formatProductData here to access cartItems
              const formatProductData = (data) => {
                const formattedProduct = {
                  id: productId,
                  name: data.name || "",
                  category: data.category,
                  originalPrice: data.originalPrice || 0,
                  price: data.price || 0,
                  images: [],
                  description: Array.isArray(data.description) ? data.description : [],
                  rating: data.rating || 0,
                  cart: 0
                };
                if (data.downloadUrls) {
                  formattedProduct.images = Object.keys(data.downloadUrls).map((key) => {
                    const image = data.downloadUrls[key];
                    return {
                      id: parseInt(key),
                      name: `Image ${key}`,
                      src: image || "",
                      alt: `Image ${key}`,
                    };
                  });
                }
    
                console.log(cartItems);
    
                // Access cartItems here to populate cart property
                cartItems.forEach((elem) => {
                  console.log(elem.id);
                  if (formattedProduct.id === elem.id) {
                    formattedProduct.cart = elem.quantity;
                  }
                });
    
                return formattedProduct;
              };
    
              const formattedProduct = formatProductData(productData);
              setProduct(formattedProduct);
            } else {
              console.error("Product not found.");
            }
          });
    
          // Clean up the listener when the component unmounts
          return () => {
            unsubscribe();
          };
        } catch (error) {
          console.error("Error fetching product data:", error);
        }
      };
    
      fetchProductData();
    }, [productId, cartItems]);
    


  console.log(product)

  return (
    <div>
      <Header back={true}></Header>
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        {product == null ? (
          <>Loading...</>
        ) : (
          <ProductView product={product}></ProductView>
        )}
      </div>

      <Footer></Footer>
    </div>
  );
}

export default ProductPage;
